import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = React.forwardRef(({ home }, ref) => {
  const scrollToTop = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Div>
      <Links>
        <Text className="copyright">
          &#169; {new Date().getFullYear()} Icon Venture Company{" "}
        </Text>
        <NavLink to="/privacy" className="privacy" home={home && "home"}>
          Privacy Policy
        </NavLink>
        <NavLink to="/terms" className="terms" home={home && "home"}>
          Terms of Use
        </NavLink>
      </Links>
      <button onClick={scrollToTop}>&uarr;</button>
    </Div>
  );
});

const Div = styled.div`
  width: 100%;
  height: 15rem;
  background-color: var(--color-theme-black);
  display: flex;
  flex-direction: row;

  button {
    all: unset;
    font-size: 5rem;
    color: var(--color-theme-white);
    position: relative;
    right: 5%;
    cursor: pointer;
    transform: translateY(-0.5rem);
  }

  @media (max-width: 1024px) {
    padding: 0 5%;
    justify-content: space-between;
  }
`;

const Links = styled.div`
  padding-left: var(--width-medium);
  display: flex;
  width: 100%;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 1rem;
    padding-top: 2rem;
    padding-left: 5%;
    justify-content: space-evenly;

    .privacy {
      transform: translateY(-1.6rem);
    }
  }
`;

const Text = styled.p`
  font-size: 1.3rem;
  color: var(--color-tertiary-dark);
  user-select: none;
  width: 42%;

  @media (max-width: 1024px) {
    position: static;
    width: 100%;
  }
`;

const NavLink = styled(Link)`
  all: unset;
  font-size: 1.3rem;
  color: var(--color-tertiary-dark);

  cursor: pointer;
  width: 20.5%;
  line-height: 1.6;

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 1.1rem;
  }
`;

export default Footer;
