import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import worksData from "../../Data/works.json";

import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import Lines from "../../Components/Background/Lines";
import Footer from "../../Components/Navigation/Footer";

import ContentDiv from "./ContentDiv";

function WorkDetailsPage() {
  const location = useLocation();
  const workLink = location.pathname.split("/")[2];

  const work = worksData.works.filter((work) => {
    return work.link === workLink;
  })[0];

  const otherWorks = worksData.works.filter((currentWork) => {
    return currentWork.name !== work.name;
  });

  const date = new Date(Date.parse(work.date)).toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const topRef = useRef(null);
  const [topOffset, setTopOffset] = useState(0);
  const { ref, inView } = useInView({
    threshold: 1,
  });
  useEffect(() => {
    if (inView) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  }, [inView]);

  useEffect(() => {
    topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  });

  return (
    <>
      <Lines />
      <VerticalNavbar animate={false} topOffset={topOffset} />
      <Content ref={topRef}>
        <HorizontalNavbar animate={false} topOffset={topOffset} />
        <PseudoDiv ref={ref} />
        <TopDiv>
          <TitleDiv>
            <h1 className="title">{work.name}</h1>
            <div>
              <Link
                to={`/clients/${
                  work.index === 0
                    ? worksData.works[worksData.works.length - 1].link
                    : worksData.works[work.index - 1].link
                }`}
              >
                <h1>&larr;</h1>
              </Link>
              <Link
                to={`/clients/${
                  work.index === worksData.works.length - 1
                    ? worksData.works[0].link
                    : worksData.works[work.index + 1].link
                }`}
              >
                <h1>&rarr;</h1>
              </Link>
            </div>
          </TitleDiv>
          <ImageDiv>
            <img src={work.mainImage} alt="poster" />
          </ImageDiv>
          <Description>
            <h3>
              About <br />
              {work.name}
            </h3>
            <hr />
            <p>
              <span>Project type: </span>
              {work.type}
            </p>
            <p>
              <span>Date: </span>
              {date}
            </p>
            {work.technologies && (
              <p>
                <span>Technologies: </span>
                {work.technologies}
              </p>
            )}
            <p className="description">{work.description}</p>
          </Description>
        </TopDiv>

        <ContentDiv
          task={work.task}
          taskType={work.taskType}
          logo={work.logo}
          logoText={work.logoText}
          colors={work.colors}
          colorText={work.colorText}
          content={work.content}
          otherWorks={otherWorks}
        />
        <Footer ref={topRef} />
      </Content>
    </>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--vnavbar-size));
  position: absolute;
  right: 0;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

const TitleDiv = styled.div`
  width: 100%;
  padding-left: var(--width-medium);
  padding-right: 5rem;
  padding-top: 10%;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-theme-black);

  .title {
    font-size: 8rem;
    color: var(--color-theme-white);
  }

  div {
    display: flex;
  }

  a {
    text-decoration: none;
    color: var(--color-theme-white);
    font-size: 10rem;
    margin: 0 1rem;
  }

  @media (max-width: 1024px) {
    display: block;
    padding-left: 5%;
    padding-top: 5%;

    .title {
      font-size: 6rem;
    }

    a {
      margin-left: 0;
    }
  }

  @media (max-width: 525px) {
    .title {
      font-size: 4rem;
    }
  }

  @media (max-width: 250px) {
    .title {
      font-size: 3rem;
    }
  }
`;

const PseudoDiv = styled.div`
  position: absolute;
  height: var(--hnavbar-size);
  top: 0;
  width: 100%;
  background-color: var(--color-theme-black);
`;

const TopDiv = styled.div`
  width: 100%;
  padding-top: var(--hnavbar-size);
  padding-bottom: 1.5%;
  position: relative;
`;

const ImageDiv = styled.div`
  height: 60vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 20%;
  }

  @media (max-width: 1024px) {
    height: 80vh;
  }

  @media (max-width: 765px) {
    height: 40vh;
  }
`;

const Description = styled.div`
  background-color: var(--color-theme-black);
  min-height: 40%;
  max-height: 50%;
  min-width: 45%;
  max-width: 55%;
  bottom: 1.5vh;
  position: absolute;
  left: var(--width-medium);
  padding: 5rem 4rem;
  z-index: 100;

  h3 {
    color: var(--color-theme-white);
    font-size: 3rem;
    margin-bottom: 2.6rem;
  }

  hr {
    border-bottom: none;
  }

  p {
    font-size: 1.6rem;
    padding-top: 1.4rem;
    color: #fff;

    & span {
      font-size: 1.6rem;
      color: var(--color-tertiary-dark);
    }
  }

  a {
    all: unset;
    font-size: 1.6rem;
    color: var(--color-secondary-dark);
    cursor: pointer;

    span {
      font-size: 2rem;

      ::before {
        transition: all 0.4s;
        content: "";
      }
    }

    :hover {
      span {
        ::before {
          margin-left: 1rem;
        }
      }
    }
  }

  .description {
    margin: 2rem 0;
    font-size: 1.4rem;
  }

  @media (max-width: 1024px) {
    bottom: 0;
    left: 5%;
    width: 70%;
  }

  @media (max-width: 765px) {
    min-width: 75%;
    left: 0;
    position: relative;
    top: -5%;
    margin: 0 auto;

    h3 {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 525px) {
    min-width: 90%;
    padding: 4rem 2rem 1rem;

    .description {
      font-size: 1.5rem;
    }
  }
`;

export default WorkDetailsPage;
