import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import Lines from "../../Components/Background/Lines";
import SecondarySection from "../../Components/HomePage/SecondarySection";
import FinalSection from "../../Components/HomePage/FinalSection";
import useIsInViewport from "../../Hooks/useIsInViewport";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import Footer from "../../Components/Navigation/Footer";

import Hero from "./Hero";
import Carousal from "../../Components/HomePage/Carousal";

function HomePage() {
  const { width } = useWindowDimensions();
  const div1 = useRef(null);
  const div2 = useRef(null);
  const div3 = useRef(null);
  const div4 = useRef(null);
  const div5 = useRef(null);

  const [activeDiv, setActiveDiv] = useState(0);
  const [topOffset, setTopOffset] = useState(0);

  const isInViewport1 = useIsInViewport(div1);
  const isInViewport2 = useIsInViewport(div2);
  const isInViewport3 = useIsInViewport(div3);
  const isInViewport4 = useIsInViewport(div4);
  const isInViewport5 = useIsInViewport(div5);

  useEffect(() => {
    if (isInViewport1) {
      setActiveDiv(0);
    }
    if (isInViewport2) {
      setActiveDiv(1);
    }
    if (isInViewport3) {
      setActiveDiv(2);
    }
    if (isInViewport4) {
      setActiveDiv(3);
    }
    if (isInViewport5) {
      setActiveDiv(4);
    }
  }, [
    isInViewport1,
    isInViewport2,
    isInViewport3,
    isInViewport4,
    isInViewport5,
  ]);

  //Calculates Top Offset for visibility of horizontal navbar
  const log = () => {
    setTopOffset(div1.current.getBoundingClientRect().y);
  };

  return (
    <Home>
      <Lines />
      <VerticalNavbar
        navlinks={["top", "design", "develop", "digitalize", "bottom"]}
        activeDiv={activeDiv}
        topOffset={topOffset}
      />
      <Content onScroll={log}>
        <HorizontalNavbar topOffset={topOffset} isHomePage={true} />
        <Div id="top" ref={div1}>
          <Hero />
        </Div>
        <Div id="design" ref={div2}>
          {width > 1024 ? (
            <SecondarySection index={0} />
          ) : (
            <Carousal heading={"Design"} />
          )}
        </Div>
        <Div id="develop" ref={div3}>
          {width > 1024 ? (
            <SecondarySection index={1} />
          ) : (
            <Carousal heading={"Develop"} />
          )}
        </Div>
        <Div id="digitalize" ref={div4}>
          {width > 1024 ? (
            <SecondarySection index={2} />
          ) : (
            <Carousal heading={"Digitalize"} />
          )}
        </Div>
        <Div id="bottom" ref={div5}>
          <FinalSection ref={div1} />
        </Div>
        <FooterDiv>
          <Footer ref={div1} />
        </FooterDiv>
      </Content>
    </Home>
  );
}

const Home = styled.main`
  display: flex;
  flex-direction: row;
  user-select: none;
`;

const Content = styled.div`
  position: absolute;
  right: 0;
  width: calc(100% - var(--vnavbar-size));
  -ms-overflow-style: none;
  scrollbar-width: none;

  background-color: var(--color-primary-dark);

  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  max-height: 100vh;

  @media (max-width: 1024px) {
    width: 100%;
    scroll-snap-type: none;
  }

  &::webkit-scrollbar {
    display: none;
  }
`;

const Div = styled.div`
  scroll-snap-align: start;
  height: 100vh;
  /* margin: 1px 0; */
`;

const FooterDiv = styled.div`
  scroll-snap-align: end;
  height: 15rem;
`;

export default HomePage;
