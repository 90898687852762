import React from "react";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import Card from "./ServiceCard";
import Redirect from "./Redirect";

import services from "../../Data/services.json";

function SecondarySection({ index }) {
  let serviceType;

  switch (index) {
    case 0:
      serviceType = "design";
      break;
    case 1:
      serviceType = "develop";
      break;
    case 2:
      serviceType = "digitalize";
      break;
    default:
      break;
  }

  return (
    <Section>
      <TopDiv>
        <HeadingText>
          {index === 0 ? (
            <HeadingCurrentSpan>Design</HeadingCurrentSpan>
          ) : (
            <HeadingLinkSpan to="/#design" smooth>
              Design
            </HeadingLinkSpan>
          )}
          ,&nbsp;
          {index === 1 ? (
            <HeadingCurrentSpan>Develop</HeadingCurrentSpan>
          ) : (
            <HeadingLinkSpan to="/#develop" smooth>
              Develop
            </HeadingLinkSpan>
          )}
          &nbsp;&&nbsp;
          {index === 2 ? (
            <HeadingCurrentSpan>Digitalize</HeadingCurrentSpan>
          ) : (
            <HeadingLinkSpan to="/#digitalize" smooth>
              Digitalize
            </HeadingLinkSpan>
          )}
        </HeadingText>
      </TopDiv>
      <BottomDiv>
        <CardGallery>
          {services[serviceType].map((service, index) => {
            return (
              <Card
                key={index}
                delay={Number(`0.${index}`)}
                title={service.title}
                description={service.description}
                icon={service.icon}
                link={service.link}
              />
            );
          })}
        </CardGallery>
        <Redirect />
      </BottomDiv>
    </Section>
  );
}

const Section = styled.section`
  scroll-snap-align: start;
  height: 100vh;
  margin: 1px 0;
  overflow: hidden;
`;

const TopDiv = styled.div`
  height: 40%;
  width: 100%;
  background-color: var(--color-theme-black);
  position: relative;
`;

const HeadingText = styled.p`
  font-family: Lato, "sans-serif";
  font-weight: 600;
  z-index: 99;
  text-decoration: none;
  position: absolute;
  bottom: -2rem;
  left: var(--width-medium);
  font-size: 9.5rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--color-secondary-dark);

  @media (max-width: 1440px) {
    font-size: 7rem;
  }

  @media (max-width: 1100px) {
    font-size: 6.5rem;
  }
`;

const HeadingCurrentSpan = styled.span`
  font-size: 9.5rem;
  -webkit-text-fill-color: var(--color-secondary-dark);
  -webkit-text-stroke: 1px var(--color-secondary-dark);

  @media (max-width: 1440px) {
    font-size: 7rem;
  }
  @media (max-width: 1100px) {
    font-size: 6.5rem;
  }
`;

const HeadingLinkSpan = styled(HashLink)`
  all: unset;
  cursor: pointer;
  &:hover {
    -webkit-text-fill-color: var(--color-secondary-dark);
  }
`;

const CardGallery = styled.div`
  width: fit-content;
  height: 100%;
  position: relative;
  left: var(--width-medium);
  display: flex;
  flex-direction: row;
`;

const BottomDiv = styled.div`
  height: 60%;
  width: 100%;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column-reverse;
`;

export default SecondarySection;
