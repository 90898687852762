import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Lines from "../../Components/Background/Lines";
import Footer from "../../Components/Navigation/Footer";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import { Link } from "react-router-dom";

function PrivacyPage() {
  const topRef = useRef(null);
  const [topOffset, setTopOffset] = useState(0);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  }, [inView]);

  useEffect(() => {
    topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  });

  return (
    <>
      <Lines />
      <VerticalNavbar animate={false} topOffset={topOffset} />
      <Content ref={topRef}>
        <HorizontalNavbar animate={false} topOffset={topOffset} />
        <PseudoDiv ref={ref} />
        <TopDiv>
          <h1>Privacy Policy</h1>
        </TopDiv>
        <Policy>
          <Paragraph>
            By working with and using iconventurecompany.com, you agree to the
            collection and use of your information as stated in this policy.
          </Paragraph>
          <Paragraph>
            All references to ‘Icon Venture Company’, ‘IVC’, ‘our’, ‘us’, ‘we’
            or ‘company’ on this website and opt-in notices are deemed to refer
            to Icon Venture Company, its subsidiaries, affiliates, parent
            companies and any of their related businesses. Icon Venture Company
            respects your privacy and is committed to protecting the personal
            information that you provide to us. This statement explains our
            policies and practices regarding the use and disclosure of your
            personal information by us.
          </Paragraph>
          <Paragraph>
            Please note that we review and update this Privacy Policy from time
            to time as needed without notice. Therefore, you should review the
            terms of this policy periodically to make sure that you are aware of
            how we collect and use personal information. By using our website,
            you consent to the collection and use of your personal information
            by us as explained below.
          </Paragraph>
          <Heading>PERSONAL INFORMATION</Heading>
          <Paragraph>
            The notice applies to all information collected or submitted on the
            Icon Venture Company website or via phone or email with an Icon
            Venture Company representative. The types of information collected
            are personal, billing and project related. We might also collect
            information from you regarding third parties (i.e. hosting company
            etc.) You have complete control over your personal information. In
            general, you can visit our website without providing us with any
            personal information however, there are instances where we must have
            your personal information for us to grant you access to our
            protected and secured sites.
          </Paragraph>
          <Paragraph>
            This information may include registration data (your name, address,
            email address, phone number, title, etc.), information request data,
            and response data (“User Information”). When you receive your
            confirmation email or when you receive any email from the list
            server, you will be given instructions on how to remove yourself
            from the list.
          </Paragraph>
          <Heading>USE OF USER INFORMATION</Heading>
          <Paragraph>
            Icon Venture Company uses the information you provide about yourself
            to provide our services. If you decide that we should not use your
            personal User Information to contact you, please let us know and we
            will not use that information for such purpose. However, please do
            not submit any User Information if you are less than 18 years of
            age.
          </Paragraph>
          <Heading>DISCLOSURE OF USER INFORMATION</Heading>
          <Paragraph>
            Icon Venture Company does not sell, trade, or transfer User
            Information to third parties. However, we may share User Information
            with our business partners for marketing, advertising, or
            product/service offering purposes. For example, we provide User
            Information to our service providers for direct emailing of our
            newsletters, online surveys, or notifications on Icon Venture
            Company to our viewers. We also disclose User Information if: we
            have your consent; we need to share it to provide you with the
            products and/or services you requested; we respond to a court order
            or you violate our Terms and Conditions.
          </Paragraph>
          <Paragraph>
            You may separately agree to provide your personal information to
            third parties that provide content for Icon Venture Company, to
            access and/or use their products and/or services. If you agree to
            provide such information to these third parties, then your personal
            information will be subject to their privacy policies.
          </Paragraph>
          <Heading>ACCURACY AND SECURITY</Heading>
          <Paragraph>
            The accuracy and security of the User Information are important to
            Icon Venture Company. Currently, you may review and request updates
            to your User Information retained by contacting us. If you contact
            us to correct your User Information, we will attempt to correct such
            inaccuracies promptly. Icon Venture Company is concerned with the
            security of your User Information and is committed to taking
            reasonable steps to protect it from unauthorized access and use of
            that personal information.
          </Paragraph>
          <Paragraph>
            To that end, we put in place the appropriate physical, electronic
            and managerial policies and procedures to secure your personal User
            Information. We also continue to implement procedures to maintain
            accurate, complete, and current User Information.
          </Paragraph>
          <Heading>COOKIES</Heading>
          <Paragraph>
            Icon Venture Company uses “cookies”. A cookie is a small data file
            that a website can transfer to a visitor’s hard drive to keep
            records of the visits to such a site. A cookie contains information
            such as your username and password that helps us recognize the pages
            you have visited and improve future visits, but the only personal
            information a cookie can contain is the information that you provide
            yourself.
          </Paragraph>
          <Paragraph>
            A cookie cannot read data off of your hard drive or read cookie
            files created by other sites. Information stored in cookies may be
            encrypted, however, we do not store your credit card number in
            cookies. If you prefer not to accept a cookie, you can set your web
            browser to warn you before accepting cookies or you can refuse all
            cookies by turning them off in your web browser. However, access to
            some of our secured website(s) may require the use of cookies, which
            you must enable only cookies that get sent back to the originating
            server. Otherwise, you can still access most of the features on our
            website even without accepting a cookie.
          </Paragraph>
          <Heading>EXTERNAL LINKS</Heading>
          <Paragraph>
            Icon Venture Company website provides links to other third-party
            websites. Even if the third party is affiliated with Icon Venture
            Company through a business partnership or otherwise, we are not
            responsible for the privacy policies or practices or the content of
            such external links. These links are provided to you for convenience
            purposes only and you access them at your own risk.
          </Paragraph>
          <Heading>TERMS OF USE</Heading>
          <Paragraph>
            Please also see our <Link to="/terms">Terms and Conditions</Link>,
            which describe the restrictions, disclaimers, indemnification, and
            limitation of liability governing the use of the entire Icon Venture
            Company website.
          </Paragraph>
          <Heading>GENERAL</Heading>
          <Paragraph>
            If you have questions regarding our Privacy Statement, please
            contact Icon Venture Company by email at{" "}
            <a
              href="mailto:info@iconventurecompany.com"
              target="_blank"
              rel="noreferrer"
            >
              info@iconventurecompany.com
            </a>
          </Paragraph>
        </Policy>
        <Footer ref={topRef} />
      </Content>
    </>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--vnavbar-size));
  position: absolute;
  right: 0;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

const Policy = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 63vh;
  padding-top: 13rem;
  padding-bottom: 13rem;
  padding-left: var(--width-medium);
  padding-right: var(--width-large);

  @media (max-width: 1024px) {
    padding: 2.5rem 5%;
  }
`;

const Heading = styled.h1`
  text-transform: uppercase;
  font-family: Montserrat, "sans-serif";
  font-weight: 600;
  font-size: 3rem;
  color: var(--color-theme-black);
  margin: 2rem 0;
  z-index: 100;
`;

const Paragraph = styled.p`
  font-family: Lato, "sans-serif";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 1rem 0;
  z-index: 100;

  a {
    font-family: Lato, "sans-serif";
    font-weight: 400;
    font-size: 1.6rem;
    :visited {
      color: var(--color-primary-dark);
    }
  }
`;

const TopDiv = styled.div`
  height: 37vh;
  width: 100%;
  background-color: var(--color-theme-black);
  display: flex;
  flex-direction: column-reverse;

  h1 {
    z-index: 100;
    color: var(--color-theme-white);
    position: relative;
    left: var(--width-medium);
    font-size: 10rem;
  }

  @media (max-width: 1024px) {
    h1 {
      left: 5%;
      font-size: 8rem;
    }
  }

  @media (max-width: 525px) {
    h1 {
      font-size: 5rem;
      left: 5%;
      margin-bottom: 5rem;
    }
  }
`;

const PseudoDiv = styled.div`
  position: absolute;
  height: 10rem;
  top: 0;
  width: 10rem;
`;

export default PrivacyPage;
