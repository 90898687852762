import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import Lines from "../../Components/Background/Lines";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import Footer from "../../Components/Navigation/Footer";
import TopAnimation from "../../Components/Animation/TopAnimation";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

import brand from "../../Data/brand.json";

function StoryPage() {
  const { width } = useWindowDimensions();
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const [topOffset, setTopOffset] = useState(0);
  const topRef = useRef(null);
  const sliderRef = useRef(null);
  const teamRefs = useRef([]);
  const [info, setInfo] = useState(false);
  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  useEffect(() => {
    if (inView) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  }, [inView]);

  useEffect(() => {
    topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  }, []);

  return (
    <>
      <Lines />
      <VerticalNavbar topOffset={topOffset} />
      <Content ref={topRef}>
        <HorizontalNavbar topOffset={topOffset} />
        <PseudoDiv ref={ref} />
        <TopDiv>
          <h1>Our Story</h1>
        </TopDiv>
        <TopAnimation duration={1}>
          <Div className="mission">
            <SubHeadingDiv>
              <h1>{brand.missionText}</h1>
            </SubHeadingDiv>
            <DescriptionDiv>
              <h1 className="title">{brand.missionTitle}</h1>
              <div className="content">
                {brand.missionDescription.map((para, index) => {
                  return <p key={index}>{para.p}</p>;
                })}
              </div>
            </DescriptionDiv>
          </Div>
        </TopAnimation>
        <TopAnimation duration={1}>
          <Div className="values">
            <SubHeadingDiv>
              <h1>{brand.valuesText}</h1>
            </SubHeadingDiv>
            <CardDiv>
              {brand["values"].map((value, index) => {
                return (
                  <Card key={index}>
                    <h1>{value.value[0]}</h1>
                    <h3>{value.value}</h3>
                    <p>{value.description}</p>
                  </Card>
                );
              })}
            </CardDiv>
          </Div>
        </TopAnimation>
        <TopAnimation duration={1}>
          <Div>
            <SubHeadingDiv className="team">
              <h1>{brand.teamText}</h1>
              <p>{brand.teamDescription}</p>
            </SubHeadingDiv>
            <TeamDiv
              navigation={true}
              modules={[Navigation]}
              loop={true}
              slidesPerView={"auto"}
              ref={sliderRef}
            >
              {brand["teamMembers"].map((member, index) => {
                return (
                  <TeamMember
                    key={index}
                    number={index + 1}
                    total={brand["teamMembers"].length}
                  >
                    <div className="background">
                      <img src={member.image} alt={member.name} />
                    </div>

                    <div
                      className="info"
                      style={
                        width < 1024
                          ? info
                            ? { transform: `translateY(0)` }
                            : { transform: `translateY(80%)` }
                          : undefined
                      }
                      ref={(element) => teamRefs.current.push(element)}
                    >
                      <div className="visible">
                        <p className="name">{member.name}</p>
                        <p className="position">{member.position}</p>
                        <p
                          className="plus"
                          style={
                            width < 1024
                              ? info
                                ? {
                                    transform:
                                      "rotate(45deg) translateY(-1rem)",
                                  }
                                : {
                                    transform: "rotate(0deg) translateY(-1rem)",
                                  }
                              : undefined
                          }
                          onClick={() => {
                            setInfo(!info);
                          }}
                        >
                          +
                        </p>
                      </div>
                      <div className="more">
                        <p>{member.about}</p>
                        <a href={member.link} target="_blank" rel="noreferrer">
                          in
                        </a>
                      </div>
                    </div>
                  </TeamMember>
                );
              })}
            </TeamDiv>
          </Div>
          <NavBox>
            <div className="swiper-button-prev" onClick={handlePrev}>
              &larr;
            </div>
            <div className="swiper-button-next" onClick={handleNext}>
              &rarr;
            </div>
          </NavBox>
        </TopAnimation>
        <Footer ref={topRef} />
      </Content>
    </>
  );
}

const Content = styled.div`
  width: calc(100% - var(--vnavbar-size));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  background-color: var(--color-theme-white);
  overflow-x: hidden;

  .swiper-button-next {
    all: unset;
    font-size: 6rem;
    color: black;
    &::after {
      content: "";
    }
  }
  .swiper-button-prev {
    all: unset;
    font-size: 6rem;
    color: black;
    &::after {
      content: "";
    }
  }

  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

const PseudoDiv = styled.div`
  position: absolute;
  height: 10rem;
  top: 0;
  width: 10rem;
`;

const TopDiv = styled.div`
  height: 37vh;
  width: 100%;
  background-color: var(--color-theme-black);
  display: flex;
  flex-direction: column-reverse;

  h1 {
    z-index: 100;
    color: var(--color-theme-white);
    position: relative;
    left: var(--width-medium);
    font-size: 10rem;
  }

  @media (max-width: 525px) {
    h1 {
      font-size: 5rem;
      left: 5%;
    }
  }
`;

const Div = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  z-index: 100;
  background-color: transparent;
  margin-top: 0;

  &.mission {
    margin-top: 6rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-left: 0;
    min-height: 0;

    &.values {
      margin-top: 6rem;
    }
  }
`;

const SubHeadingDiv = styled.div`
  margin-left: calc(var(--width-medium));
  height: 100%;
  min-width: calc(var(--width-large));
  position: relative;

  &.team {
    margin-left: var(--width-medium);
    min-width: 0;
    max-width: var(--width-large);
    padding-top: 12rem;
    padding-right: 2rem;
  }

  p {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-family: Lato, "sans-serif";
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
  }

  h1 {
    font-size: 3.5rem;
    font-family: Montserrat, "sans-serif";
    display: inline;
    color: var(--color-secondary-dark);
  }

  @media (max-width: 1024px) {
    margin-left: 5%;
    margin-bottom: 3rem;

    h1 {
      font-size: 2.5rem;
    }

    &.team {
      margin-left: 5%;
      max-width: 100%;
      padding-top: 0;
    }
  }
`;

const DescriptionDiv = styled.div`
  max-width: calc(4 * var(--width-large));
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 6rem;

  .title {
    font-size: 3.5rem;
    font-family: Montserrat, "sans-serif";
    color: var(--color-primary-dark);
  }

  .content {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    p {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-left: 5%;
    padding-right: 2rem;

    .title {
      font-size: 2rem;
    }
  }
`;

const CardDiv = styled.div`
  width: calc(4 * var(--width-large));
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Card = styled.div`
  min-height: 30rem;
  width: 32%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  padding-top: 0;
  padding-left: 0rem;
  max-width: 25%;

  h1 {
    color: var(--color-primary-dark);
    font-size: 7rem;
    font-family: Montserrat, "sans-serif";
    margin-top: -1rem;
  }

  h3 {
    font-size: 2.3rem;
    color: var(--color-primary-dark);
  }
  p {
    font-size: 1.4rem;
    font-family: Lato, "sans-serif";
    font-weight: 400;
    line-height: 1.5;
  }

  @media (max-width: 1024px) {
    min-width: 100%;
    padding-left: 5%;

    h3 {
      width: 100%;
    }

    p {
      font-size: 1.5rem;
    }
  }
`;

const TeamDiv = styled(Swiper)`
  width: calc(100% - var(--width-medium) - var(--width-large));
  min-height: 100%;
  margin-bottom: 6rem;

  @media (max-width: 1024px) {
    width: 100%;
    min-height: 0;
    height: 50vh;
    padding: 0 5%;
  }
`;

const TeamMember = styled(SwiperSlide)`
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10rem;
  position: relative;
  color: var(--color-theme-black);
  overflow: hidden;
  margin-right: 10%;

  @media (max-width: 1024px) {
    width: 90%;
  }

  &:hover {
    .info {
      transform: translateX(0);
    }
  }

  .plus {
    display: none;
    font-size: 4rem;
    transition: all 0.4s;
  }

  @media (max-width: 1024px) {
    .plus {
      display: inline;
      position: absolute;
      right: 2rem;
    }
  }

  background-color: var(--color-theme-black);

  .background {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &::after {
    content: "0${(props) => props.number}/0${(props) => props.total}";
    position: absolute;
    top: 0.5rem;
    font-size: 1.5rem;
    font-style: italic;
    left: 1rem;
    color: var(--color-theme-white);
  }

  .info {
    width: 85%;
    height: 100%;
    position: absolute;
    right: 0;
    background-color: #fff;
    transform: translateX(80%);
    transition: all 0.4s;
    display: flex;

    @media (max-width: 1024px) {
      width: 100%;
      height: 85%;
      bottom: 0;
      transform: translateX(0);
      transform: translateY(${(props) => (props.hovered ? "0" : "80%")});
      flex-direction: column;
    }

    .visible {
      width: 20%;
      height: 100%;
      display: flex;
      writing-mode: vertical-rl;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 0;

      .name {
        font-family: Montserrat, "sans-serif";
        font-size: 1.5rem;
        font-weight: 800;
      }

      .position {
        font-family: Montserrat, "sans-serif";
        font-size: 1.2rem;
        font-weight: 600;
        /* color: #999; */
      }

      @media (max-width: 1024px) {
        height: 20%;
        width: 100%;
        writing-mode: horizontal-tb;
        padding: 2rem;
        flex-direction: column;
        align-items: baseline;
        justify-content: flex-start;

        .name {
          font-size: 2rem;
        }
      }
    }

    .more {
      height: 100%;
      width: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem;
      padding-right: 3rem;
      align-items: flex-start;
      color: #000;

      p {
        font-size: 1.5rem;
      }

      a {
        text-decoration: none;
        color: inherit;
        align-self: center;
        font-size: 1.4rem;
      }

      @media (max-width: 1024px) {
        height: 100%;
        width: 100%;

        p {
          font-size: 1.4rem;
        }

        a {
          align-self: flex-start;
        }
      }
    }
  }
`;

const NavBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5%;
  height: 5rem;
  z-index: 101;
  cursor: pointer;
  user-select: none;
  margin-top: -7rem;
  margin-bottom: 5rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export default StoryPage;
