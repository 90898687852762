import React, { useState, useEffect } from "react";
import styled from "styled-components";

function ImageLoader({ image, index }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = image.location;
    img.onload = () => {
      setIsLoaded(true);
    };
  }, [image.location]);

  return (
    <ImageDiv row={image.row} col={image.col}>
      {isLoaded ? (
        <img src={image.location} alt={index} loading="lazy" />
      ) : (
        <LoaderDiv />
      )}
    </ImageDiv>
  );
}

const ImageDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  grid-column: span ${(props) => props.col};
  grid-row: span ${(props) => props.row};
  pointer-events: ${(props) => !props.interact && "none"};
  user-select: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 525px) {
    grid-column: span ${(props) => (Number(props.col) > 2 ? 2 : 1)};
    grid-column: span ${(props) => props.interact && "2"};
    min-height: ${(props) => props.interact && "20vh"};
    grid-row: span 1;
  }
`;

const LoaderDiv = styled.div`
  height: 100%;
  min-height: 20rem;
  width: 100%;
  background-color: gray;
  grid-column: span ${(props) => props.col};
  grid-row: span ${(props) => props.row};

  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }
`;

export default ImageLoader;
