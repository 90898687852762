import React, { useRef, useCallback } from "react";
import styled from "styled-components";
import TopAnimation from "../../Components/Animation/TopAnimation";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import Youtube from "../ServiceDetails/Youtube";

function ContentDiv({
  task,
  taskType,
  logo,
  logoText,
  colors,
  colorText,
  content,
  otherWorks,
}) {
  const sliderRef = useRef(null);
  const { width } = useWindowDimensions();

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  return (
    <>
      <TopAnimation duration={1}>
        <TaskDiv>
          <h1 className="task">Task</h1>
          <div>
            <h1>{taskType}</h1>
            <p>{task}</p>
          </div>
        </TaskDiv>
        <LogoDiv>
          <div className="image">
            <img src={logo} alt={logo} />
          </div>
          <p>{logoText}</p>
        </LogoDiv>
      </TopAnimation>
      <TopAnimation duration={1}>
        <ColorsDiv>
          <h1 className="colors">Colors</h1>
          <div className="side">
            <div className="colorbox">
              {colors.map((color, index) => {
                return (
                  <Color key={index} color={color}>
                    <p>{color}</p>
                  </Color>
                );
              })}
            </div>
            <p className="text">{colorText}</p>
          </div>
        </ColorsDiv>
      </TopAnimation>
      <TopAnimation duration={1}></TopAnimation>
      {content.map((div, index) => {
        if (div.type === "left") {
          return (
            <TopAnimation duration={1} key={index}>
              <DivLeft>
                <div className="top">
                  <h1 className="title">{div.text}</h1>
                  <p>{div.description}</p>
                </div>
                <Swiper
                  slidesPerView={"auto"}
                  loop={div.images.length > 1 && true}
                  spaceBetween={20}
                  autoplay={{
                    enabled: div.autoplay && true,
                    delay: 1,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  navigation={!div.autoplay && true}
                  speed={1000}
                  modules={[Autoplay, Navigation]}
                >
                  {div.images.map((image, index) => {
                    return (
                      <SwiperSlide
                        key={image.location + index}
                        style={
                          width > 1024
                            ? image.orientation
                              ? { width: "50%" }
                              : {}
                            : {}
                        }
                      >
                        <Image onContextMenu={(e) => e.preventDefault()}>
                          {image.type === "image" ? (
                            <img src={image.location} alt={image.location} />
                          ) : (
                            <Youtube videoId={image.location} width={"90%"} />
                          )}
                        </Image>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </DivLeft>
            </TopAnimation>
          );
        } else if (div.type === "three") {
          return (
            <TopAnimation duration={1} key={index}>
              <DivMiddle>
                <div className="top">
                  <h1 className="title">{div.text}</h1>
                  <p>{div.description}</p>
                </div>
                <Swiper
                  enabled={width > 1024 ? false : true}
                  slidesPerView={width > 1024 ? 3 : 1}
                  loop={div.images.length > 1 && true}
                  spaceBetween={5}
                  autoplay={{
                    enabled: width > 1024 ? false : true,
                    delay: 1,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  speed={1000}
                  modules={[Autoplay]}
                >
                  {div.images.map((image, index) => {
                    return (
                      <SwiperSlide
                        key={image.location + index}
                        style={image.orientation && { width: "50%" }}
                      >
                        <Image onContextMenu={(e) => e.preventDefault()}>
                          {image.type === "image" ? (
                            <img src={image.location} alt={image.location} />
                          ) : (
                            <Youtube videoId={image.location} width={"90%"} />
                          )}
                        </Image>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </DivMiddle>
            </TopAnimation>
          );
        } else if (div.type === "two") {
          return (
            <TopAnimation duration={1} key={index}>
              <DivMiddle>
                <div className="top">
                  <h1 className="title">{div.text}</h1>
                  <p>{div.description}</p>
                </div>
                <Swiper
                  slidesPerView={width > 1024 ? 2 : 1}
                  loop={div.images.length > 1 && true}
                  spaceBetween={10}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  speed={1000}
                  modules={[Autoplay]}
                >
                  {div.images.map((image, index) => {
                    return (
                      <SwiperSlide
                        key={image.location + index}
                        style={image.orientation && { width: "50%" }}
                      >
                        <Image onContextMenu={(e) => e.preventDefault()}>
                          {image.type === "image" ? (
                            <img src={image.location} alt={image.location} />
                          ) : (
                            <Youtube videoId={image.location} width={"90%"} />
                          )}
                        </Image>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </DivMiddle>
            </TopAnimation>
          );
        } else {
          return (
            <TopAnimation duration={1} key={index}>
              <DivMiddle>
                <div className="top">
                  <h1 className="title">{div.text}</h1>
                  <p>{div.description}</p>
                </div>
                <Swiper
                  slidesPerView={"1"}
                  loop={div.images.length > 1 && true}
                  spaceBetween={10}
                  // navigation={true}
                  modules={[Navigation]}
                >
                  {div.images.map((image, index) => {
                    return (
                      <SwiperSlide
                        key={image.location + index}
                        style={image.orientation && { width: "50%" }}
                      >
                        <Image onContextMenu={(e) => e.preventDefault()}>
                          {image.type === "image" ? (
                            <img src={image.location} alt={image.location} />
                          ) : (
                            <Youtube
                              videoId={image.location}
                              width={"100%"}
                              height={width < 1024 && `${width * 0.47}px`}
                            />
                          )}
                        </Image>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </DivMiddle>
            </TopAnimation>
          );
        }
      })}
      <TopAnimation duration={1}>
        <QuoteDiv>
          <h1>Looks Impressive? Let's work together</h1>
          <p className="text">
            send over your project and get a quote from our team.
          </p>
          <QuoteButton to="/quote">
            <p>Get a Quote</p>
            <p className="arrow">&rarr;</p>
          </QuoteButton>
        </QuoteDiv>
      </TopAnimation>
      <OtherWorksDiv>
        {width < 1024 && (
          <AllButton to="/clients">
            <p>All Works</p>
            <p className="arrow">&rarr;</p>
          </AllButton>
        )}
        <SliderDiv>
          <AllDiv to="/clients">
            <h1>&rarr;</h1>
            <h1 className="all">All</h1>
          </AllDiv>
          <Slider
            navigation={true}
            modules={[Navigation]}
            loop={true}
            slidesPerView={width > 1024 ? 2 : 1}
            slidesPerGroup={width > 1024 ? 2 : 1}
            ref={sliderRef}
            spaceBetween={"5%"}
          >
            {otherWorks.map((work, index) => {
              return (
                <OtherWork key={index} index={index} work_index={index + 1}>
                  <h3 className="work_name">{work.name}</h3>
                  <p className="work_type">{work.type}</p>
                  <Link to={`/clients/${work.link}`}>
                    <img
                      className="work_image"
                      src={work.mainImage}
                      alt={work.name}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </Link>
                </OtherWork>
              );
            })}
          </Slider>
        </SliderDiv>
        <div className="info">
          <h1>Other Works</h1>
          <NavBox>
            <div className="swiper-button-prev" onClick={handlePrev}>
              &larr;
            </div>
            <div className="swiper-button-next" onClick={handleNext}>
              &rarr;
            </div>
          </NavBox>
        </div>
      </OtherWorksDiv>
    </>
  );
}

const TaskDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-top: 7rem;

  .task {
    position: absolute;
    font: 600 2.5rem Montserrat, "sans-serif";
    left: var(--width-medium);
    color: var(--color-secondary-dark);
  }

  div {
    margin-left: calc(var(--width-medium) + var(--width-large));
    padding-right: var(--width-large);

    h1 {
      font: 600 2rem Montserrat, "sans-serif";
      margin-bottom: 2rem;
      color: var(--color-primary-dark);
    }

    p {
      font: 400 1.4rem/1.5 Lato, "sans-serif";
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 5rem;

    .task {
      position: relative;
      left: 5%;
      margin-bottom: 5rem;
    }

    div {
      margin-left: 5%;
      padding-right: 5%;
    }
  }
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-top: 7rem;
  min-height: 20rem;
  align-items: center;

  .image {
    position: absolute;
    left: var(--width-medium);
    width: var(--width-large);
    padding-right: 4rem;
  }

  img {
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  p {
    margin-left: calc(var(--width-medium) + var(--width-large));
    padding-right: var(--width-large);

    font: 400 1.4rem/1.5 Lato, "sans-serif";
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 5rem;

    .image {
      position: relative;
      left: 5%;
      margin-right: 0 auto;
      margin-bottom: 3rem;
      width: 70%;
    }

    p {
      margin-left: 10%;
      padding-right: 10%;
      width: 100%;
    }
  }
`;

const ColorsDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-top: 7rem;

  .colors {
    position: absolute;
    font: 600 2.5rem Montserrat, "sans-serif";
    left: var(--width-medium);
    color: var(--color-secondary-dark);
  }

  .colorbox {
    display: grid;
    width: calc(3 * var(--width-large));
    grid-template-columns: auto auto auto;
    margin-left: calc(var(--width-medium) + var(--width-large));
    row-gap: 2rem;
    margin-bottom: 5rem;
  }

  .side {
    display: flex;
    flex-direction: column;
    width: 100%;

    .text {
      margin-left: calc(var(--width-medium) + var(--width-large));
      font-size: 1.5rem;
      padding-right: var(--width-large);
      font: 400 1.4rem/1.5 Lato, "sans-serif";
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 5rem;

    .colors {
      position: relative;
      left: 5%;
      margin-bottom: 5rem;
    }

    .colorbox {
      width: 80%;
      grid-template-columns: auto auto;
      margin-left: 5%;
      padding-right: 5%;
    }

    .side {
      .text {
        margin-left: 5%;
        padding-right: 5%;
      }
    }
  }

  @media (max-width: 525px) {
    .colorbox {
      grid-template-columns: auto;
    }
  }
`;

const Color = styled.div`
  height: 8.5rem;
  width: 80%;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 2rem;
  position: relative;
  box-shadow: #00000070 0 1.5rem 5rem;

  ::after {
    z-index: 2;
    content: "";
    width: 25%;
    height: 100%;
    position: absolute;
    right: 0;
    background-color: #ffffff50;
  }

  p {
    z-index: 3;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    margin-right: 1.8rem;
    font-family: Lato, "sans-serif";
    font-weight: 600;
  }

  @media (max-width: 525px) {
    width: 60%;
  }
`;

const Image = styled.div`
  height: 100%;
  width: 100%;
  cursor: grab;
  display: flex;
  align-items: center;

  user-select: none;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const DivLeft = styled.div`
  width: 100%;
  margin: 3rem 0;
  min-height: 5rem;
  display: flex;
  flex-direction: row;

  .swiper {
    height: 40vh;
    width: 100vw;
  }

  .swiper-slide {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: row;
  }

  /* --swiper-navigation-size: 10rem; */

  .swiper-button-next {
    color: var(--color-secondary-dark);
    ::after {
      font-size: 7rem;
    }
    right: 2rem;

    @media (max-width: 765px) {
      ::after {
        font-size: 5rem;
      }
      right: 1rem;
    }
  }

  .swiper-button-prev {
    color: var(--color-secondary-dark);
    ::after {
      font-size: 7rem;
    }
    left: 2rem;
    @media (max-width: 765px) {
      ::after {
        font-size: 5rem;
      }
      left: 1rem;
    }
  }

  .top {
    margin-left: var(--width-medium);
    padding-right: 8rem;
    height: auto;
    min-width: calc(2 * var(--width-large));
    max-width: calc(2 * var(--width-large));
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font: 600 2.8rem Montserrat, "sans-serif";
      left: var(--width-medium);
      margin-bottom: 3rem;
      color: var(--color-primary-dark);
    }

    p {
      font: 400 1.4rem/1.5 Lato, "sans-serif";
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 3rem 0;

    .top {
      min-width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0 5%;
    }

    p {
      width: 100%;
      padding: 0;
      margin-bottom: 3rem;
    }

    .swiper {
      padding: 0 5%;
      height: 40vh;
    }
  }
`;

const DivMiddle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 3rem 0;

  .swiper {
    height: 70vh;
    width: 78%;
    margin-left: var(--width-medium);
    margin-top: 3rem;
    display: grid;
    place-items: center;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-button-next {
    color: var(--color-secondary-dark);
    ::after {
      font-size: 7rem;
    }
    right: 2rem;
    @media (max-width: 765px) {
      ::after {
        font-size: 5rem;
      }
      right: 1rem;
    }
  }

  .swiper-button-prev {
    color: var(--color-secondary-dark);
    ::after {
      font-size: 7rem;
    }
    left: 2rem;
    @media (max-width: 765px) {
      ::after {
        font-size: 5rem;
      }
      left: 1rem;
    }
  }

  .top {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .title {
    font: 600 2.8rem Montserrat, "sans-serif";
    margin-left: var(--width-medium);
    margin-bottom: 3rem;
    min-width: var(--width-large);
    max-width: var(--width-large);
    padding-right: 1rem;
    color: var(--color-primary-dark);
  }

  p {
    font: 400 1.4rem/1.5 Lato, "sans-serif";
    padding-right: var(--width-large);
  }

  @media (max-width: 1024px) {
    .top {
      flex-direction: column;
    }

    .title {
      margin: 5%;
      margin-top: 0;
    }

    p {
      padding: 0 5%;
      margin-bottom: 3rem;
    }

    .swiper {
      width: 100%;
      margin-top: 2rem;
      margin-left: 0;
      padding: 0 5%;
      height: 40vh;
    }
  }
`;

const QuoteDiv = styled.div`
  width: 100%;
  height: 40vh;
  /* margin: 7.5rem 0; */
  padding-left: var(--width-medium);
  display: flex;
  flex-direction: column;
  padding-top: 3rem;

  h1 {
    font: 600 3rem Montserrat, "sans-serif";
    color: var(--color-primary-dark);
  }

  .text {
    font: 400 2rem/1.5 Lato, "sans-serif";
    margin-bottom: 6rem;
  }

  @media (max-width: 1024px) {
    margin-top: 2rem;
    padding: 0 5%;
    height: 35vh;
  }
`;

const QuoteButton = styled(Link)`
  all: unset;
  height: 5rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  background-color: var(--color-secondary-dark);
  outline: 1px solid var(--color-secondary-dark);
  font-family: Lato, sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  transition: all 0.4s;
  cursor: pointer;
  z-index: 101;

  p {
    color: var(--color-theme-black);
    transition: all 0.4s;
    font-weight: 600;
    font-size: 1.5rem;

    &.arrow {
      font-size: 3.5rem;
      padding-bottom: 0.5rem;
    }
  }

  :hover,
  :focus {
    background-color: transparent;
    color: var(--color-secondary-dark);

    svg {
      translate: 5px 0;
    }

    p {
      color: var(--color-secondary-dark);

      &.arrow {
        transform: translateX(1rem);
      }
    }
  }
`;

const OtherWorksDiv = styled.div`
  width: 100%;
  height: calc(100vh - var(--hnavbar-size));
  background-color: var(--color-theme-black);
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  h1 {
    font-size: 8rem;
    color: var(--color-theme-white);
    padding-right: 5%;
  }

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: var(--width-medium);
    padding-right: 8%;
    justify-content: space-between;
  }

  .swiper-button-next {
    all: unset;
    font-size: 6rem;
    color: white;
    &::after {
      content: "";
    }
  }
  .swiper-button-prev {
    all: unset;
    font-size: 6rem;
    color: white;
    &::after {
      content: "";
    }
  }

  @media (max-width: 1024px) {
    .info {
      padding: 0 5%;
    }

    h1 {
      font-size: 6rem;
    }
  }
  @media (max-width: 525px) {
    h1 {
      font-size: 5rem;
      margin-bottom: 4rem;
    }
  }
`;

const AllDiv = styled(Link)`
  all: unset;
  width: calc(var(--width-large) - 1%);
  height: 100%;
  position: absolute;
  right: 0;
  background-color: var(--color-theme-white);
  top: 0;
  cursor: pointer;
  transition: all 0.4s;

  display: grid;
  place-items: center;
  overflow: hidden;

  h1 {
    font-size: 12rem;
    color: var(--color-secondary-dark);
    transition: all 0.4s;
  }

  .all {
    position: absolute;
    bottom: -5%;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px var(--color-theme-white);
    transition: all 0.4s;
  }

  :hover {
    background-color: var(--color-secondary-dark);

    h1 {
      color: var(--color-theme-white);
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SliderDiv = styled.div`
  width: 100%;
  height: 65%;
  padding-right: calc(var(--width-large) - 1%);
  padding-left: var(--width-medium);
  background-color: var(--color-theme-white);
  position: relative;

  @media (max-width: 1024px) {
    height: 60% !important;
    padding: 0 5%;
  }
`;

const Slider = styled(Swiper)`
  height: 100%;
  width: 100%;
  position: relative;
`;

const OtherWork = styled(SwiperSlide)`
  height: 100%;
  width: 50%;
  padding-top: ${(props) => (props.index % 2 === 0 ? "4rem" : "8rem")};
  padding-left: ${(props) => (props.index % 2 === 0 ? "7rem" : "0")};
  padding-right: ${(props) => (props.index % 2 === 0 ? "0" : "7rem")};

  .work_name {
    font-size: 3rem;
    font-family: Montserrat, "sans-serif";

    ::before {
      content: "0${(props) => props.work_index}";
      display: inline-block;
      transform: rotate(-90deg) translateX(0.5rem);
      font-size: 1.4rem;
      color: #999;
      margin-right: 2rem;
    }
  }

  .work_type {
    font-size: 1.6rem;
    font-family: Lato, "sans-serif";
    color: #999;
    margin-bottom: 2rem;
    margin-left: 3.5rem;
  }

  a {
    display: block;
    height: 30rem;
    width: 100%;
  }

  .work_image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1024px) {
    height: 50%;
    padding: 2.5rem 0;
    width: 100%;
  }
`;

const NavBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 5rem;
  z-index: 101;
  cursor: pointer;
  user-select: none;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const AllButton = styled(QuoteButton)`
  margin: 2rem 5%;
  height: 7rem;
`;

export default ContentDiv;
