import React from "react";
import styled from "styled-components";
import SideAnimation from "../Animation/SideAnimation";

const SubHeading = ({ text, service = false }) => {
  return (
    <SubHeadingText service={service}>
      <SideAnimation duration={1} x={-40}>
        {text}
      </SideAnimation>
    </SubHeadingText>
  );
};

export default SubHeading;

const SubHeadingText = styled.div`
  z-index: 99;
  text-decoration: none;
  position: absolute;
  left: var(--width-medium);
  max-width: var(--width-large);
  font-family: Montserrat, sans-serif;
  font-size: 4.5rem;
  line-height: 4.5rem;
  font-weight: 800;
  color: var(--color-theme-black);
  letter-spacing: -1px;
  padding-right: 2rem;
  color: var(--color-primary-dark);

  @media (max-width: 1280px) {
    position: relative;
    max-width: 100%;
    left: ${(props) => (props.service ? 0 : "var(--width-medium)")};
    margin-bottom: 2rem;
  }

  @media (max-width: 1024px) {
    font-size: 7rem;
    left: ${(props) => (props.service ? 0 : "5%")};
  }

  @media (max-width: 525px) {
    font-size: 5rem;
    margin-bottom: 2rem;
  }
`;
