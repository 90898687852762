import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import Lines from "../../Components/Background/Lines";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";

function NotFound() {
  return (
    <Page>
      <Lines />
      <VerticalNavbar />
      <Main>
        <HorizontalNavbar />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
          className="content"
        >
          <h1>404</h1>
          <p>not found</p>
        </motion.div>
      </Main>
    </Page>
  );
}

const Page = styled.div`
  overflow: hidden;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  right: 0;
  width: calc(100% - var(--vnavbar-size));
  height: 100vh;
  background-color: var(--color-primary-dark);
  color: var(--color-secondary-dark);

  & h1 {
    font-size: 24rem;
    margin-left: 0;
    text-align: center;
    font-family: "Montserrat", "sans-serif";
    font-weight: 500;
  }

  .content {
    z-index: 99;
    margin: auto 0;
    line-height: 12rem;
  }

  & p {
    font-size: 3rem;
    margin: 0;
    text-align: center;
    font-family: "Lato", "sans-serif";
    font-weight: 500;
    letter-spacing: 1.6rem;
  }

  @media (max-width: 1024px) {
    width: 100vw;

    h1 {
      font-size: 16rem;
    }
  }

  @media (max-width: 525px) {
    width: 100vw;

    h1 {
      font-size: 10rem;
    }
    p {
      letter-spacing: 0;
      font-size: 3rem;
    }
  }
`;

export default NotFound;
