import styled from "styled-components";
import Youtube from "./Youtube";
import ImageLoader from "../../Components/ServicePage/ImageLoader";

function List(props) {
  return (
    <Section hasTitle={props.hasTitle}>
      {props.images.map((image, index) => {
        if (image.link) {
          return (
            <Image
              as={"a"}
              href={image.link}
              target="_blank"
              rel="noreferrer"
              key={image.link}
              row={image.row}
              col={image.col}
              interact
            >
              <img src={image.location} alt={index} loading="lazy" />
              <DownloadButton>Download</DownloadButton>
            </Image>
          );
        } else if (image.type === "video") {
          return (
            <Image key={image + index} row={image.row} col={image.col} interact>
              <Youtube videoId={image.location} imgSize={image.res} />
            </Image>
          );
        } else {
          return (
            <ImageLoader key={image + index} image={image} index={index} />
          );
        }
      })}
    </Section>
  );
}

const Section = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
  overflow: hidden;
  padding-left: calc(var(--width-medium) + var(--width-large));
  padding-left: ${(props) =>
    props.hasTitle
      ? "calc(var(--width-medium) + var(--width-large))"
      : "var(--width-medium)"};
  padding-right: 5%;
  margin: 6rem 0;
  @media (max-width: 1280px) {
    padding-left: var(--width-medium);
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
  }

  @media (max-width: 525px) {
    margin: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  grid-column: span ${(props) => props.col};
  grid-row: span ${(props) => props.row};
  pointer-events: ${(props) => !props.interact && "none"};
  user-select: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 525px) {
    grid-column: span ${(props) => (Number(props.col) > 2 ? 2 : 1)};
    grid-column: span ${(props) => props.interact && "2"};
    min-height: ${(props) => props.interact && "20vh"};
    grid-row: span 1;
  }
`;

const DownloadButton = styled.div`
  height: 3rem;
  width: 10rem;
  display: grid;
  place-items: center;
  border-radius: 50px;
  color: var(--color-primary-dark);
  background-color: var(--color-tertiary-dark);
  font-size: 1.6rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

export default List;
