import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

function Lines() {
  return (
    <Container
      as={motion.div}
      intial={{ maxHeight: 0 }}
      animate={{ maxHeight: "100vh" }}
      transition={{ duration: 4 }}
    >
      <Line className="medium" />
      <Line className="small" />
      <Line className="small" />
      <Line className="large" />
      <Line className="large" />
      <Line className="small" />
      <Line className="small" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  height: 100vh;
  width: calc(100% - var(--vnavbar-size));
  background: transparent;
  z-index: 98;
  pointer-events: none;

  @media (max-width: 1024px) {
    display: none;
  } ;
`;

const Line = styled.div`
  /* border-right: #cae2ff 1px solid; */
  border-right: #80808030 1px solid;

  &.small {
    width: var(--width-small);
  }

  &.medium {
    width: var(--width-medium);
  }

  &.large {
    width: var(--width-large);
  }
`;

export default Lines;
