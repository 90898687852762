import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

function SideAnimation(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const variants = {
    initial: { opacity: 0, x: `${props.x}%` },
    final: { opacity: 1, x: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("final");
    } else {
      controls.start("initial");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="initial"
      variants={variants}
      animate={controls}
      transition={{ duration: props.duration }}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        zIndex: 100,
      }}
    >
      {props.children}
    </motion.div>
  );
}

export default SideAnimation;
