import React from "react";
import styled from "styled-components";
import ServiceCardCar from "./ServiceCardCar";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import services from "../../Data/services.json";

function Carousal({ heading }) {
  return (
    <Div>
      <Heading>
        <h1>{heading}</h1>
      </Heading>
      <Container>
        <Swiper slidesPerView={"auto"} loop={true}>
          {services[heading.toLowerCase()].map((service, index) => {
            return (
              <SwiperSlide key={index}>
                <ServiceCardCar
                  title={service.title}
                  about={service.description}
                  link={service.link}
                  icon={service.icon}
                  index={index + 1}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <Button to="/services">
        <p>all services</p>
        <p>&rarr;</p>
      </Button>
    </Div>
  );
}

const Div = styled.div`
  height: 101%;
  width: 100%;
  background-color: var(--color-theme-black);
`;

const Heading = styled.div`
  color: var(--color-secondary-dark);
  padding: 80px 2% 15px 6%;

  h1 {
    font-size: 7rem;
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 50%;
  padding-left: 6%;
  margin-bottom: 3rem;

  .swiper {
    height: 100%;
    margin-right: 0;
  }

  .swiper-slide {
    height: 100%;
    background-color: var(--color-theme-white);
    width: 86.5%;
    margin-right: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    padding: 30px 6% 0 6%;
  }
`;

const Button = styled(Link)`
  all: unset;
  max-width: 15rem;
  display: flex;
  align-items: center;
  padding: 2rem 3rem;
  justify-content: space-between;
  background-color: var(--color-secondary-dark);
  color: var(--color-theme-black);
  font-size: 2.2rem;
  margin-left: 6%;
  margin-top: 2%;

  p {
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

export default Carousal;
