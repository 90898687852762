import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Lines from "../../Components/Background/Lines";
import Footer from "../../Components/Navigation/Footer";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";

function TermsPage() {
  const topRef = useRef(null);
  const [topOffset, setTopOffset] = useState(0);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  }, [inView]);

  useEffect(() => {
    topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  });

  return (
    <>
      <Lines />
      <VerticalNavbar animate={false} topOffset={topOffset} />
      <Content ref={topRef}>
        <HorizontalNavbar animate={false} topOffset={topOffset} />
        <PseudoDiv ref={ref} />
        <TopDiv>
          <h1>Terms of Use</h1>
        </TopDiv>
        <Policy>
          <Paragraph>
            This website is owned and maintained by Icon Venture Company
          </Paragraph>
          <Paragraph>
            These terms and conditions govern your use of this website; by using
            this website and Icon Venture Company, you accept these terms and
            conditions in full. If you disagree with these terms and conditions
            or any part of these terms and conditions, you must not use this
            website. You must be at least 18 years of age to use this website.
          </Paragraph>
          <Paragraph>
            Icon Venture Company starts working on a project upon receipt of a
            down payment. Unless otherwise specified in writing, the client is
            charged the remaining balance in installments during the completion
            of the project. All the services provided are made life upon
            completion of the project when all payments are cleared. If
            applicable, hosting, domain, legal retention charges and name
            registration fees must be paid every month.
          </Paragraph>
          <Paragraph>
            Additional services come at additional fees, on a fee-for-service
            basis, or at an hourly rate. If clients require additional services,
            the correspondent fee is added to their invoice and they are charged
            accordingly.
          </Paragraph>
          <Paragraph>Delays in project completion may occur when:</Paragraph>
          <ul>
            <li>There is a delay in any payments</li>
            <li>
              The client requests further changes in the design, structure, or
              content of the project
            </li>
            <li>
              There is a delay in the receipt of any materials from the client
            </li>
            <li>
              Time frame estimates are approximate only and are subject to
              change at any time at Icon Venture Company’s discretion. At any
              time, and at its whole discretion, Icon Venture Company may decide
              to put a project on hold.
            </li>
          </ul>
          <Paragraph>
            Icon Venture Company does not assume any responsibility or liability
            for the content of the websites it designs and or/hosts. Icon
            Venture Company has no control over any information, materials, or
            products found thereon. Icon Venture Company does not serve as a
            consultant for its clients, and they are entirely responsible for
            all that is found on its websites. Because of the nature of web
            design and programming work, websites always appear differently on
            different computers. Screen resolution, operating system, and
            browsing software are just a few examples of variables that may
            affect the way a website looks. The client must be aware of these
            possible variations and Icon Venture Company will not be held
            responsible for them.
          </Paragraph>
          <Heading>LIMITED RIGHT TO USE</Heading>
          <Paragraph>
            This website is owned and operated by Icon Venture Company unless
            otherwise specified, all materials on this website are the property
            of Icon Venture Company and are protected by the copyright laws of
            Republic of India, and throughout the world by the applicable
            copyright laws. You may only view, print, and/or download one copy
            of the materials from this website on any single computer on device
            solely for your personal, informational and non-commercial use. No
            materials published by Icon Venture Company on this website, in
            whole are in part, may be copied, reproduced, modified, republished,
            uploaded, posted, transmitted, or distributed in any form or by any
            means without prior written permission from Icon Venture Company
          </Paragraph>
          <Heading>RESTRICTED ACCESS</Heading>
          <Paragraph>
            Access to certain areas of this website is restricted. Icon Venture
            Company reserves the right to restrict access to certain areas of
            this website, or indeed this entire website, at Icon Venture
            Company’s discretion. If Icon Venture Company gives you a user ID
            and password to enable you to access restricted areas of this
            website or other content or services, you must ensure that the user
            ID and password are kept confidential. Icon Venture Company may
            disable your user ID and password at Icon Venture Company’s sole
            discretion without notice or explanation.
          </Paragraph>
          <Heading>ACCEPTABLE USE</Heading>
          <Paragraph>
            You must not use this website in any way that causes, or may cause,
            damage to this website or impairment of the availability or
            accessibility of the website; or in any way which is unlawful,
            illegal, fraudulent, or harmful for purpose or activity. You must
            not use this website to copy, store, host, transmit, send, use,
            publish or distribute any material which consists of (or is linked
            to) any spyware, computer virus, Trojan horse, worm, keystroke
            logger, rootkit, or other malicious computer software. You must not
            conduct any systematic or automatic data collection activities on or
            about this website without Icon Venture Company’s written consent.
          </Paragraph>
          <Heading>USER CONTENT</Heading>
          <Paragraph>
            In these terms and conditions, “your user content” means material
            (including without limitation text, images, audio material, video
            material, and audio-visual material) that you submit to this
            website, for whatever purpose. You grant to Icon Venture Company a
            worldwide, irrevocable, non-exclusive, royalty-free license to use,
            reproduce, adapt, publish, translate and distribute your user
            content in any existing or future media. You also grant Icon Venture
            Company the right to sub-license these rights, and the right to
            bring an action for infringement of these rights. Your user content
            must not be illegal or unlawful, must not infringe any third party’s
            legal rights, and must not be capable of giving rise to legal action
            whether against you or Icon Venture Company or a third party. You
            must not submit any user content to the website that is or has ever
            been the subject of any threatened or actual legal proceedings or
            another similar complaint. Icon Venture Company reserves the right
            to edit or remove any material submitted to this website, stored on
            Icon Venture Company’s servers, or hosted or published on this
            website.
          </Paragraph>
          <Heading>NO WARRANTIES</Heading>
          <Paragraph>
            This website is provided “as is” without any representations or
            warranties, express or implied. Icon Venture Company makes no
            representations or warranties about this website or the information
            and materials provided on this website. Without prejudice to the
            generality of the foregoing paragraph, Icon Venture Company does not
            warrant that this website will be constantly available, or available
            at all; or that the information on this website is complete, try,
            accurate or non-misleading. Nothing on this website constitutes or
            is meant to constitute, advice of any kind.
          </Paragraph>
          <Heading>LIMITATIONS OF LIABILITY</Heading>
          <Paragraph>
            Icon Venture Company will not be liable to you for the contents of,
            or use of, or otherwise in connection with, this website:
          </Paragraph>
          <ul>
            <li>for any indirect, special, or consequential loss; or</li>
            <li>
              for any business losses, loss of revenue, income, profits or
              anticipated savings, loss of contracts or business relationships,
              loss of reputation or goodwill, or loss or corruption of
              information or data
            </li>
          </ul>
          <Heading>ASSIGNMENT</Heading>
          <Paragraph>
            Icon Venture Company may transfer, sub-contract, or otherwise deal
            with Icon Venture Company’s rights and/or obligations under these
            terms and conditions without notifying you or obtaining your
            consent. You may not transfer, sub-contract, or otherwise deal with
            your rights and/or obligations under these terms and conditions.
          </Paragraph>
          <Heading>INDEMNITY</Heading>
          <Paragraph>
            You agree to indemnify, defend and hold Icon Venture Company
            harmless from and against third-party claims, liabilities, damages,
            losses, or expenses (including reasonable attorney’s fees and costs)
            arising out of, based on, or in connection with your access and/or
            use of this website.
          </Paragraph>
          <Heading>OTHER PARTIES</Heading>
          <Paragraph>
            You accept that, as a limited liability entity, Icon Venture Company
            has an interest in limiting the personal liability of its officers
            and employees. You agree that you will not bring any claim
            personally against Icon Venture Company’s officers or employees in
            respect of any losses you suffer in connection with the website. You
            agree that the limitations of warranties and liability set out in
            this website disclaimer will protect Icon Venture Company’s
            officers, employees, agents, subsidiaries, successors, assigns, and
            sub-contractors as well as Icon Venture Company
          </Paragraph>
          <Heading>UNENFORCEABLE PROVISIONS</Heading>
          <Paragraph>
            If any provision of this website disclaimer is or is to be found to
            be, unenforceable under applicable law, that will not affect the
            enforceability of the other provisions of this website disclaimer.
          </Paragraph>
          <Heading>BREACHES OF THESE TERMS AND CONDITIONS</Heading>
          <Paragraph>
            Without the prejudice to Icon Venture Company’s other rights under
            these terms and conditions, if you breach these terms and conditions
            in any way, Icon Venture Company may take such action as Icon
            Venture Company deems appropriate to deal with the breach, including
            suspending your access to the website, prohibiting you from
            accessing the website, blocking computers using your IP address from
            accessing the website, contacting your internet service provider to
            request that they block your access to the website and/or bringing
            court proceedings against you.
          </Paragraph>
          <Heading>ENTIRE AGREEMENT</Heading>
          <Paragraph>
            These terms and conditions – together with documents – constitute
            the entire agreement between you and Icon Venture Company about your
            use of this website, and supersede all previous agreements in
            respect of your use of this website.
          </Paragraph>
        </Policy>
        <Footer ref={topRef} />
      </Content>
    </>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--vnavbar-size));
  position: absolute;
  right: 0;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

const Policy = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 63vh;
  padding-top: 13rem;
  padding-bottom: 13rem;
  padding-left: var(--width-medium);
  padding-right: var(--width-large);

  @media (max-width: 1024px) {
    padding: 2.5rem 5%;
  }

  ul {
    margin: 1rem 2rem;
  }

  li {
    font-family: Lato, "sans-serif";
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
  }
`;

const Heading = styled.h1`
  text-transform: uppercase;
  font-family: Montserrat, "sans-serif";
  font-weight: 600;
  font-size: 3rem;
  color: var(--color-theme-black);
  margin: 2rem 0;
  z-index: 100;
`;

const Paragraph = styled.p`
  font-family: Lato, "sans-serif";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 1rem 0;
  z-index: 100;
`;

const TopDiv = styled.div`
  height: 37vh;
  width: 100%;
  background-color: var(--color-theme-black);
  display: flex;
  flex-direction: column-reverse;

  h1 {
    z-index: 100;
    color: var(--color-theme-white);
    position: relative;
    left: var(--width-medium);
    font-size: 10rem;
  }

  @media (max-width: 1024px) {
    h1 {
      left: 5%;
      font-size: 8rem;
    }
  }

  @media (max-width: 525px) {
    h1 {
      font-size: 5rem;
      left: 5%;
      margin-bottom: 5rem;
    }
  }
`;

const PseudoDiv = styled.div`
  position: absolute;
  height: 10rem;
  top: 0;
  width: 10rem;
`;

export default TermsPage;
