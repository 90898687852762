import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TopAnimation from "../Animation/TopAnimation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Service(props) {
  return (
    <TopAnimation duration={1}>
      <Div to={props.link}>
        <Description>
          <div className="letter">
            <img src={props.icon} alt={props.title} />
          </div>
          <h3>{props.title}</h3>
          <p>{props.description}</p>
          <div className="icon">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </Description>
        <Image>
          <img
            src={props.image}
            alt={props.title}
            onContextMenu={(e) => e.preventDefault()}
          />
        </Image>
      </Div>
    </TopAnimation>
  );
}

// const Div = styled.div`
const Div = styled(Link)`
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: calc(var(--width-medium) + var(--width-large));
  margin-bottom: 7rem;
  z-index: 100;
  background-color: transparent;

  @media (max-width: 1280px) {
    flex-direction: column;
    padding-left: var(--width-medium);
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-left: 5%;
  }
`;

const Image = styled.div`
  width: calc(2 * var(--width-large));

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: 0% 0%;
    /* height: 60%; */
  }

  @media (max-width: 1280px) {
    width: calc(4 * var(--width-large));
  }

  @media (max-width: 1024px) {
    width: 90%;

    img {
      width: 100%;
    }
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--width-large);
  padding-right: 2rem;
  cursor: pointer;
  gap: 1.5rem;
  position: relative;

  .letter {
    margin: 0;
    padding: 0;
    line-height: 6rem;
    color: gray;
    transition: all 0.4s;
    text-transform: capitalize;
    opacity: 0.5;
    font-size: 8rem;
    font-family: Montserrat, "sans-serif";
    font-weight: 500;
  }

  h3 {
    color: var(--color-theme-black);
    font-size: 2.4rem;
    font-family: Montserrat, "sans-serif";
    font-weight: 600;
  }

  p {
    /* color: #999; */
    font-size: 1.4rem;
    line-height: 1.5;
    transition: all 0.4s;
    opacity: 0.6;
    font-family: Lato, "sans-serif";
    font-weight: 400;
  }

  .icon {
    color: var(--color-secondary-dark);
    font-size: 3.5rem;
    padding-left: 1rem;
    display: flex;

    svg {
      transition: all 0.4s;
    }

    &::before {
      opacity: 0;
      content: "more";
      font-size: 1.5rem;
      position: absolute;
      left: 0;
      transition: all 0.4s;
    }
  }

  :hover {
    .letter {
      opacity: 1;
    }

    p {
      opacity: 1;
    }

    .icon {
      svg {
        transform: translateX(150%);
      }

      &::before {
        opacity: 1;
      }
    }
  }
  @media (max-width: 1280px) {
    min-width: calc(4 * var(--width-large));
    gap: 2.5rem;
    margin-bottom: 3rem;
  }

  @media (max-width: 1024px) {
    width: 90%;

    .letter {
      opacity: 1;
    }

    h3 {
      font-size: 2.4rem;
    }
  }
`;

export default Service;
