import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MobileHorizontalNavbar from "./MobileHorizontalNavbar";

function HorizontalNavbar({
  topOffset,
  animate = true,
  isHomePage = false,
  isLight = false,
}) {
  const [showBackground, setShowBackground] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (topOffset < -40) {
      setShowBackground(true);
    } else {
      setShowBackground(false);
    }
  }, [topOffset]);

  const navbarVariants = {
    start: { x: "-50%", opacity: 0 },
    entry: { x: "0%", opacity: 1 },
    backgroundOff: { opacity: 0 },
    backgroundOn: { opacity: 1 },
  };

  return width > 1024 ? (
    <NavbarBody
      as={motion.div}
      variants={navbarVariants}
      initial={animate ? "start" : "entry"}
      animate={"entry"}
      transition={{ duration: 0.5, delay: 1 }}
      className={isHomePage ? "home" : "not-home"}
      light={isLight ? "true" : "false"}
    >
      <AnimatePresence>
        {showBackground && (
          <motion.div
            initial="backgroundOff"
            variants={navbarVariants}
            animate="backgroundOn"
            exit="backgroundOff"
            className="body"
            transition={{ duration: 0.1 }}
          />
        )}
      </AnimatePresence>

      <Link
        to="/services"
        className={`service ${({ isActive }) => (isActive ? "active" : "")}`}
        light={isLight ? "true" : "false"}
      >
        Services
      </Link>
      <Link
        to="/clients"
        className={`work ${({ isActive }) => (isActive ? "active" : "")}`}
        light={isLight ? "true" : "false"}
      >
        Clients
      </Link>
      <Link
        to="/story"
        className={`about ${({ isActive }) => (isActive ? "active" : "")}`}
        light={isLight ? "true" : "false"}
      >
        Brand Story
      </Link>
      <Link
        to="/connect"
        className={`contact ${({ isActive }) => (isActive ? "active" : "")}`}
        light={isLight ? "true" : "false"}
      >
        Connect Now
      </Link>
      <Quote to="/quote" light={isLight ? "true" : "false"}>
        Get a Quote <FontAwesomeIcon icon={faArrowRight} />
      </Quote>
    </NavbarBody>
  ) : (
    <MobileHorizontalNavbar />
  );
}

const NavbarBody = styled.div`
  width: calc(100% - var(--vnavbar-size));
  height: var(--hnavbar-size);
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.1s;
  z-index: 101;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: calc(100% - 17px);

  &.home {
    padding-right: 17px;
  }

  .body {
    background-color: ${(props) =>
      props.light === "true" ? "#fff" : "var(--color-theme-black)"};
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow-x: hidden;
  }

  .service {
    left: calc(var(--width-medium));
  }
  .work {
    left: calc(var(--width-medium) + 2 * var(--width-small));
  }
  .about {
    left: calc(
      var(--width-medium) + 2 * var(--width-small) + var(--width-large)
    );
  }
  .contact {
    left: calc(
      var(--width-medium) + 2 * var(--width-small) + 2 * var(--width-large)
    );
  }
`;

const Quote = styled(NavLink)`
  all: unset;
  display: ${(props) => (props.light === "true" ? "none" : "flex")};
  font-size: 1.8rem;
  font-family: Lato, sans-serif;
  /* font-weight: 300; */
  background-color: var(--color-secondary-dark);
  outline: 1px solid var(--color-theme-black);
  height: 5rem;
  padding: 0 3rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(var(--width-medium) + 4 * var(--width-large));
  transition: all 0.3s;
  cursor: pointer;
  z-index: 101;

  svg {
    font-size: 1.8rem;
    margin-left: 1rem;
    transition: all 0.3s;
  }

  :hover,
  :focus {
    background-color: var(--color-theme-black);
    outline: 1px solid var(--color-secondary-dark);
    color: var(--color-secondary-dark);

    svg {
      translate: 5px 0;
    }
  }
`;

const Link = styled(NavLink)`
  font-size: 1.8rem;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) =>
    props.light === "true"
      ? "var(--color-theme-black)"
      : "var(--color-secondary-dark)"};
  transition: color 0.3s;
  position: absolute;
  font-weight: 500;
  z-index: 100;
  left: 0;

  &.active {
    color: ${(props) =>
      props.light === "true"
        ? "var(--color-secondary-dark)"
        : "var(--color-theme-white)"};
  }

  &::before {
    content: "";
    position: absolute;
    transform: translate(-120%, 1rem);
    background-color: var(--color-secondary-dark);
    width: 0;
    height: 2px;
    transition: width 0.3s;
  }

  &:hover {
    color: ${(props) =>
      props.light === "true"
        ? "var(--color-secondary-dark)"
        : "var(--color-theme-white)"};

    &::before {
      width: 4rem;
    }
  }
`;

export default HorizontalNavbar;
