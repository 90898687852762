import { Route, Routes } from "react-router-dom";
import { HomePage } from "./Pages/Home";
import { ServicePage } from "./Pages/Services";
import { NotFound } from "./Pages/NotFound";
import { ServiceDetailsPage } from "./Pages/ServiceDetails";
import { WorksPage } from "./Pages/Works";
import { WorkDetailsPage } from "./Pages/WorkDetails";
import { ContactPage } from "./Pages/Contact";
import { QuotationPage } from "./Pages/Quote";
import { StoryPage } from "./Pages/Story";
import { PrivacyPage } from "./Pages/Privacy";
import { TermsPage } from "./Pages/Terms";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/services/:name" element={<ServiceDetailsPage />} />
        <Route path="/clients" element={<WorksPage />} />
        <Route path="/clients/:name" element={<WorkDetailsPage />} />
        <Route path="/story" element={<StoryPage />} />
        <Route path="/connect" element={<ContactPage />} />
        <Route path="/quote" element={<QuotationPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
