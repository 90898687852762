import React from "react";
import styled from "styled-components";
import TopAnimation from "../../Components/Animation/TopAnimation";
import CapabilityCard from "../../Components/ServicePage/CapabilityCard";

function List(props) {
  return (
    <TopAnimation duration={1} y={10}>
      <Section hasTitle={props.hasTitle}>
        {props.list.map((element, index) => {
          return (
            <CapabilityCard
              key={element.name + index}
              index={index + 1}
              title={element.name}
              description={element.about}
            />
          );
        })}
      </Section>
    </TopAnimation>
  );
}

const Section = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 51% 50%;
  overflow: hidden;
  padding-left: ${(props) =>
    props.hasTitle
      ? "calc(var(--width-medium) + var(--width-large))"
      : "var(--width-medium)"};
  margin-bottom: 2rem;

  @media (max-width: 1280px) {
    padding-left: var(--width-medium);
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
  }

  @media (max-width: 525px) {
    grid-template-columns: 100%;
  }
`;

export default List;
