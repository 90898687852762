import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import Lines from "../../Components/Background/Lines";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import Service from "../../Components/ServicePage/Service";
import SubHeading from "../../Components/ServicePage/SubHeading";
import TopDiv from "../../Components/ServicePage/TopDiv";
import Footer from "../../Components/Navigation/Footer";

import services from "../../Data/services.json";

function ServicesPage() {
  const [topOffset, setTopOffset] = useState(0);
  const topRef = useRef(null);
  const setVisibility = (isVisible) => {
    if (isVisible) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  };
  useEffect(() => {
    if (topRef) {
      topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [topRef]);

  return (
    <>
      <Lines />
      <VerticalNavbar topOffset={topOffset} animate={false} />
      <Content ref={topRef}>
        <HorizontalNavbar topOffset={topOffset} animate={false} />
        <TopDiv
          text={"What We Do"}
          type={"main"}
          getOffsetBool={setVisibility}
        />
        <Section className="first">
          <SubHeading text={"Design Services"} />
          {services["design"].map((service, index) => {
            return (
              <Service
                key={index}
                title={service.title}
                description={service.description}
                link={service.link}
                image={service.image}
                icon={service.icon}
              />
            );
          })}
        </Section>
        <Section>
          <SubHeading text={"Development Services"} />
          {services["develop"].map((service, index) => {
            return (
              <Service
                key={index}
                title={service.title}
                description={service.description}
                link={service.link}
                image={service.image}
                icon={service.icon}
              />
            );
          })}
        </Section>
        <Section>
          <SubHeading text={"Digitalization Services"} />
          {services["digitalize"].map((service, index) => {
            return (
              <Service
                key={index}
                title={service.title}
                description={service.description}
                link={service.link}
                image={service.image}
                icon={service.icon}
              />
            );
          })}
        </Section>
        <Footer ref={topRef} />
      </Content>
    </>
  );
}

const Content = styled.div`
  background-color: var(--color-theme-white);
  width: calc(100% - var(--vnavbar-size));
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 100vw;
  }
  .first {
    padding-top: 7rem;
  }
`;

const Section = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 100;
  margin-top: 3rem;

  @media (max-width: 1024px) {
    margin-top: 5rem;
  }
`;

export default ServicesPage;
