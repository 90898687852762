import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import Lines from "../../Components/Background/Lines";
import TopDiv from "../../Components/ServicePage/TopDiv";
import SubHeading from "../../Components/ServicePage/SubHeading";
import TopAnimation from "../../Components/Animation/TopAnimation";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import Footer from "../../Components/Navigation/Footer";

import { useLocation, useNavigate } from "react-router-dom";
import services from "../../Data/services.json";
import List from "./List";
import Bullets from "./Bullets";
import Points from "./Points";
import Images from "./Images";
import Presentation from "./Presentation";
import { Link } from "react-router-dom";

function ServiceDetails() {
  const topRef = useRef(null);
  const [topOffset, setTopOffset] = useState(0);
  const setVisibility = (isVisible) => {
    if (isVisible) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  };
  const location = useLocation();
  const navigate = useNavigate();
  let serviceLink = location.pathname.split("/")[2];
  serviceLink = `/services/${serviceLink}`;

  useEffect(() => {
    topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  }, []);

  let service = services["design"].filter((service) => {
    return service.link === serviceLink;
  });

  if (service.length === 0) {
    service = services["develop"].filter((service) => {
      return service.link === serviceLink;
    });
  }

  if (service.length === 0) {
    service = services["digitalize"].filter((service) => {
      return service.link === serviceLink;
    });
  }

  const dummyService = {
    title: "Not found",
    sections: [],
  };

  useEffect(() => {
    if (service === dummyService) {
      navigate("/not-found");
    }
  });

  if (service.length > 0) {
    service = service[0];
  } else {
    service = dummyService;
  }

  return (
    <>
      <Lines />
      <VerticalNavbar animate={false} topOffset={topOffset} />
      <Page>
        <Content ref={topRef}>
          <HorizontalNavbar animate={false} topOffset={topOffset} />
          <TopDiv text={service.title} getOffsetBool={setVisibility} />
          {service.sections.map((sec, index) => {
            return (
              <TopAnimation duration={1} key={sec + index} y={5}>
                <Section
                  hasTitle={sec.title ? true : false}
                  isfirst={index === 0 ? true : false}
                >
                  {sec.title && <SubHeading text={sec.title} service />}
                  <p>{sec.description}</p>
                </Section>
                {sec.list && (
                  <List list={sec.list} hasTitle={sec.title ? true : false} />
                )}
                {sec.bullets && (
                  <Bullets
                    points={sec.bullets}
                    hasTitle={sec.title ? true : false}
                  />
                )}
                {sec.points && (
                  <Points
                    points={sec.points}
                    hasTitle={sec.title ? true : false}
                  />
                )}
                {sec.images && <Images images={sec.images} />}
                {sec.presentation &&
                  sec.presentation.map((ppt, index) => {
                    return <Presentation presentation={ppt} key={index} />;
                  })}
              </TopAnimation>
            );
          })}
          <div className="space">&nbsp;</div>
        </Content>
        <TopAnimation duration={1}>
          <QuoteDiv>
            <Link to="/services" className="back">
              <p>&larr; All Services</p>
            </Link>
            <h1>Looks Impressive? Let's work together</h1>
            <p className="text">
              send over your project and get a quote from our team.
            </p>
            <QuoteButton to="/quote">
              <p>Get a Quote</p>
              <p className="arrow">&rarr;</p>
            </QuoteButton>
          </QuoteDiv>
        </TopAnimation>
        <Footer ref={topRef} />
      </Page>
    </>
  );
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: var(--vnavbar-size);

  .space {
    height: 10vh;
    width: 100%;

    @media (max-width: 1024px) {
      height: 5vh;
    }
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  right: 0;

  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

const Section = styled.div`
  width: 100%;
  padding-left: ${(props) =>
    props.hasTitle
      ? "calc(var(--width-medium) + var(--width-large))"
      : "var(--width-medium)"};
  padding-right: 5%;
  margin-top: ${(props) => (props.isfirst ? "5rem" : "0")};
  margin-bottom: 2rem;

  p {
    padding-top: 1rem;
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: Lato, sans-serif;
    font-weight: 400;
  }

  @media (max-width: 1280px) {
    padding-left: var(--width-medium);
  }

  @media (max-width: 1024px) {
    padding-right: 10%;
    padding-left: 5%;
    width: 100%;
  }

  @media (max-width: 525px) {
    margin-top: 3rem;
  }
`;

const QuoteButton = styled(Link)`
  all: unset;
  height: 5rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  background-color: var(--color-secondary-dark);
  outline: 1px solid var(--color-secondary-dark);
  font-family: Lato, sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  transition: all 0.4s;
  cursor: pointer;
  z-index: 101;

  p {
    color: var(--color-theme-black);
    transition: all 0.4s;
    font-weight: 600;
    font-size: 1.5rem;

    &.arrow {
      font-size: 3.5rem;
      padding-bottom: 0.5rem;
    }
  }

  :hover,
  :focus {
    background-color: transparent;
    color: var(--color-secondary-dark);

    svg {
      translate: 5px 0;
    }

    p {
      color: var(--color-secondary-dark);

      &.arrow {
        transform: translateX(1rem);
      }
    }
  }
`;

const QuoteDiv = styled.div`
  width: 100%;
  height: 40vh;
  /* margin: 7.5rem 0; */
  padding-left: var(--width-medium);
  display: flex;
  flex-direction: column;
  /* padding-top: 3rem; */

  .back {
    all: unset;
    margin-bottom: 5rem;
    color: var(--color-secondary-dark);
    cursor: pointer;

    p {
      font-size: 2rem;
    }

    @media (max-width: 1024px) {
      margin-bottom: 2rem;
      p {
        font-size: 1.8rem;
      }
    }
  }

  h1 {
    font: 600 3rem Montserrat, "sans-serif";
    color: var(--color-primary-dark);
  }

  .text {
    font: 400 2rem/1.5 Lato, "sans-serif";
    margin-bottom: 6rem;
  }

  @media (max-width: 1024px) {
    margin-top: 2rem;
    padding: 0 5%;
  }
`;

export default ServiceDetails;
