import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function NewWork(props) {
  const date = new Date(Date.parse(props.date)).toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  return (
    <Work>
      <Heading>
        <h1>{props.title}</h1>
      </Heading>
      <Info>
        <Image
          background={props.image}
          onContextMenu={(e) => e.preventDefault()}
        >
          <Description>
            <h3>
              About <br />
              {props.title}
            </h3>
            <hr />
            <p>
              <span>Project type: </span>
              {props.type}
            </p>
            <p>
              <span>Date: </span>
              {date}
            </p>
            {props.technologies && (
              <p>
                <span>Technologies: </span>
                {props.technologies}
              </p>
            )}
            <p className="description">{`${props.description.slice(
              0,
              150
            )}...`}</p>
            <Link to={`/clients/${props.link}`}>
              See Client <span>&rarr;</span>
            </Link>
          </Description>
        </Image>
      </Info>
    </Work>
  );
}

const Work = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  @media (max-width: 595px) {
    min-height: 100vh;
  }
`;

const Heading = styled.div`
  min-height: 40vh;
  position: relative;
  width: 100vw;

  h1 {
    font-size: 8rem;
    color: var(--color-theme-white);
    position: absolute;
    bottom: -2rem;
    z-index: 2;
    left: var(--width-medium);
    margin-bottom: 2rem;
  }

  @media (max-width: 1024px) {
    height: 55vh;

    h1 {
      left: 5rem;
      bottom: 5rem;
    }
  }

  @media (max-width: 595px) {
    height: 25vh;
    min-height: 0;
    h1 {
      bottom: 0rem;
      font-size: 4rem;
      left: 3rem;
      margin-right: 3%;
    }
  }

  @media (max-width: 250px) {
    h1 {
      font-size: 3rem;
    }
  }
`;

const Info = styled.div`
  flex-direction: column;
  min-height: 63vh;
  overflow: visible;

  @media (max-width: 765px) {
    min-height: 90vh;
  }

  @media (max-width: 595px) {
    min-height: 0;
  }
`;

const Image = styled.div`
  min-height: 63vh;
  width: 100%;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  z-index: 98;

  background-image: url(${(props) => props.background});
  background-size: cover;

  @media (max-width: 1024px) {
    min-height: 85vh;
  }

  @media (max-width: 765px) {
    min-height: 50vh !important;
  }

  @media (max-width: 595px) {
    min-height: 30vh !important;
  }
`;

const Description = styled.div`
  background-color: var(--color-theme-black);
  min-height: 90%;
  width: 55%;
  bottom: 0;
  position: absolute;
  left: var(--width-medium);
  padding: 5rem 4rem;
  z-index: 999;

  h3 {
    color: var(--color-theme-white);
    font-size: 3rem;
    margin-bottom: 2.6rem;
  }

  hr {
    border-bottom: none;
  }

  p {
    font-size: 1.6rem;
    color: #fff;
    padding-top: 1.4rem;

    & span {
      font-size: 1.6rem;
      color: var(--color-tertiary-dark);
    }
  }

  a {
    all: unset;
    font-size: 1.6rem;
    color: var(--color-secondary-dark);
    cursor: pointer;

    span {
      font-size: 2rem;

      ::before {
        transition: all 0.4s;
        content: "";
      }
    }

    :hover {
      span {
        ::before {
          margin-left: 1rem;
        }
      }
    }
  }

  .description {
    margin: 2rem 0;
    font-size: 1.4rem;
  }

  @media (max-width: 1024px) {
    width: 35%;
    padding: 5rem 2rem;
    bottom: 5rem;
    height: 80%;

    p {
      font-size: 1.4rem;

      span {
        font-size: 1.4rem;
      }
    }
  }

  @media (max-width: 765px) {
    height: 60% !important;
    width: 75%;
    left: 50%;
    transform: translate(-50%, 90%);
  }

  @media (max-width: 595px) {
    height: 90% !important;
    width: 90%;
  }
`;

export default NewWork;
