import React, { useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

function ServiceCard({ delay, title, description, icon, link }) {
  const cardVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 1, delay } },
    hidden: { opacity: 0, y: "50%" },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <Card
      as={motion.div}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={cardVariants}
    >
      <Link to={link}>
        <img src={icon} alt={title} />
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
        <div className="arrow">
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </Link>
    </Card>
  );
}

const Card = styled.div`
  height: 100%;
  width: var(--width-large);
  display: flex;
  padding: 8rem 0;
  padding-right: 2%;
  cursor: pointer;

  a {
    gap: 2rem;
    all: unset;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media (max-width: 1280px) {
    padding: 4rem 0;
  }

  .arrow {
    font-size: 3rem;
    position: relative;
    color: var(--color-secondary-dark);

    &::before {
      opacity: 0;
      content: "more";
      transition: all 0.5s;
      font-size: 1.2rem;
      position: absolute;
      top: 50%;
      padding-left: 0.4rem;
    }

    svg {
      padding-left: 0.4rem;
      position: absolute;
      top: 0;
      left: 0%;
      transition: all 0.4s;
    }
  }

  &:hover {
    .arrow {
      svg {
        translate: 200% 0;
      }

      ::before {
        opacity: 1;
      }
    }

    & > * {
      opacity: 1;
    }
  }
`;

const CardTitle = styled.p`
  font-size: 2.3rem;
  font-family: "Montserrat", "sans-serif";
  font-weight: 600;
  z-index: 99;
  opacity: 0.7;
  transition: opacity 0.4s;
  min-height: 3rem;
  justify-self: baseline;
  color: var(--color-primary-dark);

  @media (max-width: 1440px) {
    font-size: 1.8rem;
  }
`;

const CardDescription = styled.p`
  justify-self: baseline;
  transition: opacity 0.4s;
  z-index: 99;
  font-family: "Lato", "sans-serif";
  font-size: 1.3rem;
  line-height: 1.5;
  padding-left: 2px;
  width: 90%;

  @media (max-width: 1440px) {
    font-size: 1rem;
  }
`;

export default ServiceCard;
