import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Lines from "../../Components/Background/Lines";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import SideAnimation from "../../Components/Animation/SideAnimation";
import TopAnimation from "../../Components/Animation/TopAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { v4 } from "uuid";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useRef } from "react";

const APIKEY = "AIzaSyDqH2PPb6V3ffiSKmJyHzAI5WRtGCvr3yE";

function ContactPage() {
  const [formData, setFormData] = useState({});
  const [phone] = useState("");
  const [message, setMessage] = useState({});
  const firebaseConfig = {
    databaseURL:
      "https://icon-venture-company-default-rtdb.asia-southeast1.firebasedatabase.app/",
  };
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);

  useEffect(() => {
    if (message.show) {
      setTimeout(() => {
        setMessage({ ...message, show: false });
      }, 3000);
    }
  }, [message]);

  const addPhone = (e) => {
    setFormData({ ...formData, phone: e });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uid = v4();
    set(
      ref(
        database,
        "contact/" + formData.firstName + formData.lastName + "_" + uid
      ),
      formData
    )
      .then(async () => {
        setMessage({
          show: true,
          title: "Success",
          message: "Your form has been submitted successfully.",
        });

        await fetch(
          "https://discord.com/api/webhooks/1039500284528967760/CgbcNYavyhWEkBnJ_p3wDoM5VyZh_dfuwX8r_3MSmJTBXQoHqUHTTGl2jfmYCYxl9d5c",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ content: "Contact form submitted" }),
          }
        );
      })
      .catch((err) => {
        console.error(err);
        setMessage({
          show: true,
          title: "Oops",
          message: "An error occurred! Please try again later.",
        });
      });
  };

  const [topOffset, setTopOffset] = useState(0);
  const topRef = useRef(null);
  const { viewRef, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  }, [inView]);

  useEffect(() => {
    if (topRef) {
      topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [topRef]);

  return (
    <div>
      <Lines />
      <VerticalNavbar animate={false} topOffset={topOffset} />
      <Content ref={topRef}>
        {message.show && (
          <MessageBox title={message.title}>
            <h4>{message.title}</h4>
            <p>{message.message}</p>
          </MessageBox>
        )}

        <HorizontalNavbar
          isLight={true}
          animate={false}
          topOffset={topOffset}
        />
        <PseudoDiv ref={viewRef} />
        <SideAnimation duration={0.8} x={-20}>
          <Heading>Connect With Us</Heading>
        </SideAnimation>
        <TopAnimation duration={0.5} delay={0.5}>
          <Form onSubmit={handleSubmit}>
            <Input>
              <label>First Name *</label>
              <input
                type="text"
                required
                placeholder="e.g. John"
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
            </Input>
            <Input>
              <label>Last Name *</label>
              <input
                type="text"
                required
                placeholder="e.g. Doe"
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
              />
            </Input>
            <Input>
              <label>Email *</label>
              <input
                type="email"
                required
                placeholder="Email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </Input>
            <Input>
              <label>Phone Number *</label>
              <PhoneInput
                required
                placeholder="e.g. 9106555101"
                defaultCountry="IN"
                value={phone}
                onChange={(e) => {
                  addPhone(e);
                }}
                style={{ "--PhoneInputCountryFlag-height": "2rem" }}
              />
            </Input>
            <Input>
              <label>Choose Your Inquiry *</label>
              <select
                required
                placeholder="select from the list"
                onChange={(e) =>
                  setFormData({ ...formData, inquiry: e.target.value })
                }
              >
                <option value="">Select From The List</option>
                <option value="Graphics Solution">Graphics Solution</option>
                <option value="Digital Marketing Solution">
                  Digital Marketing Solution
                </option>
                <option value="Web Development">Web Development</option>
                <option value="App Development">App Development</option>
                <option value="Work With Us">Work With Us</option>
              </select>
            </Input>
            <Input className="area">
              <label>How Can We Help You</label>
              <textarea
                placeholder="Describe Your Inquiry"
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Input>
            <Input className="submit">
              <label>&nbsp;</label>
              <button type="submit">
                <p>Send a Request</p>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </Input>
          </Form>
          <Info>
            <a href="mailto:info@iconventurecompany.com">
              info@iconventurecompany.com
            </a>
            <a href="tel:+919106555101">+91 9106-555-101</a>
            <p>Mon to Sat from 10:00am to 7:00pm</p>
          </Info>
        </TopAnimation>
        <Map
          as={motion.div}
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 1.3 }}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            frameBorder="0"
            loading="lazy"
            style={{ border: "none" }}
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${APIKEY}&q=place_id:ChIJkZ-APm1N4DsRrcHQTD0KMHw&zoom=12`}
          />
        </Map>
      </Content>
    </div>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--vnavbar-size));
  position: absolute;
  right: 0;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

const MessageBox = styled.div`
  position: fixed;
  top: 85vh;
  right: 10rem;
  background-color: ${(props) =>
    props.title === "Success" ? "limegreen" : "red"};
  color: white;
  height: 8rem;
  width: 20rem;
  z-index: 999;
  border-radius: 1rem;
  padding: 1rem 2rem;

  h4 {
    font-size: 2.5rem;
    font-weight: 800;
    font-family: Montserrat, "sans-serif";
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.6rem;
  }

  @media (max-width: 735px) {
    right: 3.5rem;
    h4 {
      font-size: 2rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

const PseudoDiv = styled.div`
  position: absolute;
  height: 10rem;
  top: 0;
  width: 10rem;
`;

const Heading = styled.h1`
  position: relative;
  left: calc(var(--width-medium) + 1%);
  margin-top: 13rem;
  margin-bottom: 10rem;
  font-size: 10rem;
  /* font-weight: 500; */
  padding-right: 5rem;

  @media (max-width: 1024px) {
    left: 5rem;
    padding-right: 10rem;
  }

  @media (max-width: 760px) {
    font-size: 7rem;
    margin-bottom: 3rem;
  }

  @media (max-width: 525px) {
    font-size: 5rem;
    margin-bottom: 3rem;
  }
`;

const Map = styled.div`
  position: fixed;
  right: 0;
  height: 100%;
  width: calc(var(--width-large) + var(--width-small) - 3%);
  z-index: 101;
  background-color: #777;

  @media (max-width: 1280px) {
    width: 100%;
    position: relative;
    height: 50rem;
  }
`;

const Info = styled.div`
  width: calc(3 * var(--width-large));
  position: relative;
  left: var(--width-medium);
  min-height: 2rem;
  margin-bottom: 10rem;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 3.5%;

  a,
  p {
    text-decoration: none;
    color: var(--color-theme-black);
    font-size: 1.6rem;
    font-weight: 600;
  }

  p {
    color: #999;
  }

  @media (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 80%;
    gap: 20%;
    left: 5rem;
  }
`;

const Form = styled.form`
  width: calc(3 * var(--width-large));
  position: relative;
  left: var(--width-medium);
  display: grid;
  grid-template-columns: 40% 45%;
  justify-content: space-between;
  row-gap: 2rem;
  margin-bottom: 10rem;

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
    padding: 0 5rem;
    grid-template-columns: 45% 45%;
  }

  @media (max-width: 760px) {
    grid-template-columns: 100%;
  }
`;

const Input = styled.div`
  min-height: 10rem;
  z-index: 101;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    color: gray;
    opacity: 0.8;
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.3rem;
  }

  &.area {
    @media (min-width: 1024px) {
      grid-area: 2 / 2 / span 2 / 3;
    }

    grid-row: span 2;
  }

  .PhoneInputCountryIcon {
    margin-right: 1rem;
  }

  .PhoneInput,
  .PhoneInputInput,
  input,
  textarea,
  select {
    border: none;
    background-color: #eee;
    width: 100%;
    height: 60%;
    padding: 0.5rem 2rem;
    font-size: 1.6rem;
    color: var(--color-theme-black);
    transition: all 0.3s;
    border-radius: none;

    :active,
    :focus {
      border: none;
      background-color: var(--color-theme-black);
      opacity: 1;
      color: var(--color-theme-white);

      ::placeholder {
        color: var(--color-theme-white);
      }
    }

    ::placeholder {
      color: var(--color-theme-black);
    }
  }

  option {
    background-color: #eee;
    color: var(--color-primary-dark);
    zoom: 2;
  }

  &.submit {
    button {
      border: none;
      width: 100%;
      height: 60%;
      background-color: var(--color-secondary-dark);
      display: flex;
      font-size: 2.5rem;
      align-items: center;
      justify-content: space-between;
      padding: 0.3rem 3rem;
      cursor: pointer;
      outline: 1px solid var(--color-secondary-dark);
      transition: all 0.4s;

      :hover {
        background-color: transparent;
        color: var(--color-secondary-dark);
      }
    }
  }

  textarea {
    height: 82%;
    padding: 2rem 2rem;
    resize: none;
    font-family: Lato, "sans-serif";
  }

  select {
    padding: 2rem 2rem;
  }
`;

export default ContactPage;
