import React from "react";
import styled from "styled-components";

import Stat from "./Stat";
import stats from "../../Data/stats.json";
import logos from "../../Data/home.json";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

const FinalSection = React.forwardRef((props, ref) => {
  const width = useWindowDimensions();

  return (
    <Section>
      <Content>
        <Logos>
          <Swiper
            spaceBetween={30}
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            freeMode={{
              enabled: true,
            }}
            slidesPerGroup={width.width > 1024 ? 5 : 1}
            speed={width.width > 1024 ? 5000 : 1500}
            slidesPerView={width.width > 1024 ? 5 : 1}
            loop={true}
            modules={[Autoplay]}
          >
            {logos.logos.map((logo) => {
              return (
                <Logo
                  key={logo.name}
                  name={logo.name}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <a
                    href={logo.website}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => logo.website === "" && e.preventDefault()}
                  >
                    <img src={logo.location} alt={logo.name} />
                  </a>
                  <p>{logo.name}</p>
                </Logo>
              );
            })}
          </Swiper>
        </Logos>
        <Stats>
          <Stat number={stats["Clients"]} text={"Clients"} plus />
          <Stat number={stats["Cups of Tea"]} text={"Cups of Tea"} />
          <Stat number={stats["Projects"]} text={"Projects Done"} />
          <Stat number={stats["Testimonials"]} text={"Testimonials"} />
        </Stats>
        <Heading>Our Journey</Heading>
      </Content>
    </Section>
  );
});

const Section = styled.section`
  scroll-snap-align: start;
  height: 100vh;
  margin: 1px 0;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  height: 100%;
  background-color: var(--color-theme-black);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
`;

const Heading = styled.h1`
  font-family: Lato, "sans-serif";
  font-weight: 600;
  -webkit-text-fill-color: var(--color-secondary-dark);
  -webkit-text-stroke: 1px var(--color-secondary-dark);
  z-index: 99;
  font-size: 9.5rem;
  position: relative;
  left: var(--width-medium);
  top: 2%;
  color: var(--color-secondary-dark);

  @media (max-width: 765px) {
    font-size: 7.5rem;
    left: 5%;
    top: 0;
    margin-bottom: 17rem;
    margin-right: 5%;
  }
  @media (max-width: 525px) {
    font-size: 6.5rem;
    margin-bottom: 2rem;
  }
`;

const Stats = styled.div`
  height: 30%;
  width: 100%;
  background-color: var(--color-theme-white);

  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;

  padding: 5rem 10rem;
  padding-bottom: 0;

  @media (max-width: 765px) {
    flex-wrap: wrap;
    padding: 2rem 2rem 3rem;
    grid-template-columns: 50% 50%;
    height: 20%;
  }
`;

const Logos = styled.div`
  height: 40%;
  width: 100%;
  background-color: var(--color-theme-white);
  padding: 4rem 3rem;
  padding-bottom: 0;

  .swiper {
    height: 100%;
    width: 100%;
    z-index: 101;
  }

  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
`;

const Logo = styled(SwiperSlide)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  position: relative;

  a {
    height: 50%;
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    font-family: Montserrat, "sans-serif";
    font-weight: 600;
    margin-top: 5rem;
    margin-bottom: 5rem;
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    filter: grayscale(50%);

    :hover {
      filter: grayscale(0%);
      transform: scale(1.2);
      transform: translateY(-2rem);
    }
  }

  :hover {
    p {
      opacity: 1;
    }
  }

  @media (max-width: 1024px) {
    img {
      filter: grayscale(0);
    }

    p {
      opacity: 1;
    }
  }
`;

export default FinalSection;
