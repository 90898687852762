import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";

import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import Lines from "../../Components/Background/Lines";
import SideAnimation from "../../Components/Animation/SideAnimation";
import TopAnimation from "../../Components/Animation/TopAnimation";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { v4 } from "uuid";
import { useRef } from "react";

function QuotationPage() {
  const [formData, setFormData] = useState({});
  const [phone] = useState("");
  const topRef = useRef(null);

  const [message, setMessage] = useState({});
  const firebaseConfig = {
    databaseURL:
      "https://icon-venture-company-default-rtdb.asia-southeast1.firebasedatabase.app/",
  };
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);

  useEffect(() => {
    if (message.show) {
      setTimeout(() => {
        setMessage({ ...message, show: false });
      }, 6000);
    }
  }, [message]);

  const addPhone = (e) => {
    setFormData({ ...formData, phone: e });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uid = v4();
    set(
      ref(
        database,
        "quote/" + formData.firstName + formData.lastName + "_" + uid
      ),
      formData
    )
      .then(async () => {
        setMessage({
          show: true,
          title: "Success",
          message: "Your quotation request has been received successfully.",
        });

        await fetch(
          "https://discord.com/api/webhooks/1039500284528967760/CgbcNYavyhWEkBnJ_p3wDoM5VyZh_dfuwX8r_3MSmJTBXQoHqUHTTGl2jfmYCYxl9d5c",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ content: "Quote request received" }),
          }
        );
      })
      .catch((err) => {
        console.error(err);
        setMessage({
          show: true,
          title: "Oops",
          message: "An error occurred! Please try again later.",
        });
      });
  };

  const [topOffset, setTopOffset] = useState(0);
  const { viewRef, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  }, [inView]);

  useEffect(() => {
    if (topRef) {
      topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [topRef]);

  return (
    <div>
      <Lines />
      <VerticalNavbar animate={false} topOffset={topOffset} />
      <Content ref={topRef}>
        <HorizontalNavbar
          animate={false}
          isLight={true}
          topOffset={topOffset}
        />
        {message.show && (
          <MessageBox title={message.title}>
            <h4>{message.title}</h4>
            <p>{message.message}</p>
          </MessageBox>
        )}
        <PseudoDiv ref={viewRef} />
        <Heading>
          <SideAnimation duration={0.8} x={-20}>
            Get a Quote
          </SideAnimation>
        </Heading>
        <TopAnimation duration={0.5}>
          <Form onSubmit={handleSubmit}>
            <Input>
              <label>First Name *</label>
              <input
                type="text"
                required
                placeholder="e.g. John"
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
            </Input>
            <Input>
              <label>Last Name *</label>
              <input
                type="text"
                required
                placeholder="e.g. Doe"
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
              />
            </Input>
            <Input>
              <label>Email *</label>
              <input
                type="email"
                required
                placeholder="Email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </Input>
            <Input>
              <label>Phone Number *</label>
              <PhoneInput
                required
                placeholder="e.g. 9106555101"
                defaultCountry="IN"
                value={phone}
                onChange={(e) => {
                  addPhone(e);
                }}
                style={{ "--PhoneInputCountryFlag-height": "2rem" }}
              />
            </Input>
            <Input>
              <label>Project Type *</label>
              <select
                required
                onChange={(e) =>
                  setFormData({ ...formData, projectType: e.target.value })
                }
              >
                <option value="">Choose Your Project </option>
                <optgroup label="Design">
                  <option value="Logo Designing">Logo Designing</option>
                  <option value="Visiting/ Invitation Cards">
                    Visiting/ Invitation Cards
                  </option>
                  <option value="Posters, Flyers & Banners">
                    Posters, Flyers & Banners
                  </option>
                  <option value="Certificate Designing">
                    Certificate Designing
                  </option>
                  <option value="Letter Head, Envelope Design">
                    Letter Head, Envelope Design
                  </option>
                  <option value="Brand Consultancy">Brand Consultancy</option>
                </optgroup>
                <optgroup label="Develop">
                  <option value="Portfolio Creation">Portfolio Creation</option>
                  <option value="App Development">App Development</option>
                  <option value="E-Commerce">E-Commerce</option>
                  <option value="Tech Consultancy">Tech Consultancy</option>
                </optgroup>
                <optgroup label="Digitize">
                  <option value="Social Media Marketing">
                    Social Media Marketing
                  </option>
                  <option value="Advertising Campaign">
                    Advertising Campaign
                  </option>
                  <option value="Online Platfrom Management">
                    Online Platfrom Management
                  </option>
                  <option value="Photography and Profiling">
                    Photography and Profiling
                  </option>
                </optgroup>
              </select>
            </Input>
            <Input className="area">
              <label>Project Description *</label>
              <textarea
                required
                placeholder="Describe Your Project"
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Input>
            <Input className="submit">
              <label>&nbsp;</label>
              <button type="submit">
                <p>Submit</p>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </Input>
          </Form>
        </TopAnimation>
      </Content>
    </div>
  );
}

const Content = styled.div`
  position: absolute;
  right: 0;
  width: calc(100% - var(--vnavbar-size));
  overflow: hidden;
  padding-top: 15rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100vw;
  }
`;

const MessageBox = styled.div`
  position: fixed;
  top: 85vh;
  right: 10rem;
  background-color: ${(props) =>
    props.title === "Success" ? "limegreen" : "red"};
  color: white;
  height: 8rem;
  min-width: 20rem;
  z-index: 999;
  border-radius: 1rem;
  padding: 1rem 2rem;

  h4 {
    font-size: 2.5rem;
    font-weight: 800;
    font-family: Montserrat, "sans-serif";
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.6rem;
  }

  @media (max-width: 735px) {
    right: 3.5rem;
    h4 {
      font-size: 2rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

const PseudoDiv = styled.div`
  position: absolute;
  height: 10rem;
  top: 0;
  width: 10rem;
`;

const Heading = styled.h1`
  position: relative;
  left: 20%;
  font-size: 17rem;
  margin-bottom: 5rem;

  @media (max-width: 1024px) {
    left: 5rem;
  }

  @media (max-width: 760px) {
    font-size: 13rem;
    margin-bottom: 3rem;
  }

  @media (max-width: 525px) {
    font-size: 8rem;
    margin-bottom: 3rem;
  }
`;

const Form = styled.form`
  width: calc(4 * var(--width-large));
  min-height: 50rem;
  position: relative;
  left: var(--width-medium);
  display: grid;
  grid-template-columns: 37% 50%;
  justify-content: space-between;
  row-gap: 2rem;
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
    padding: 0 5rem;
    grid-template-columns: 45% 45%;
  }

  @media (max-width: 760px) {
    grid-template-columns: 100%;
  }
`;

const Input = styled.div`
  min-height: 8rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    color: gray;
    opacity: 0.8;
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.3rem;
  }

  &.area {
    @media (min-width: 1024px) {
      grid-area: 2 / 2 / span 2 / 3;
    }

    grid-row: span 2;
  }

  .PhoneInputCountryIcon {
    margin-right: 1rem;
  }

  .PhoneInput,
  .PhoneInputInput,
  input,
  textarea,
  select {
    border: none;
    background-color: #eee;
    width: 100%;
    height: 60%;
    padding: 0.5rem 2rem;
    font-size: 1.6rem;
    color: var(--color-theme-black);
    transition: all 0.5s;
    border-radius: none;

    :active,
    :focus {
      border: none;
      background-color: var(--color-theme-black);
      opacity: 1;
      color: var(--color-theme-white);

      ::placeholder {
        color: var(--color-theme-white);
      }
    }

    ::placeholder {
      color: var(--color-theme-black);
    }
  }

  option {
    zoom: 2;
  }

  &.submit {
    button {
      float: right;
      border: none;
      width: 100%;
      height: 60%;
      background-color: var(--color-secondary-dark);
      display: flex;
      font-size: 2.5rem;
      align-items: center;
      justify-content: space-between;
      padding: 0.3rem 3rem;
      cursor: pointer;
      outline: 1px solid var(--color-secondary-dark);
      transition: all 0.4s;

      :hover {
        background-color: transparent;
        color: var(--color-secondary-dark);
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  textarea {
    height: 82%;
    padding: 2rem 2rem;
    resize: none;
    font-family: Lato, "sans-serif";
  }

  select {
    padding: 2rem 2rem;
  }
`;
export default QuotationPage;
