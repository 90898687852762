import React from "react";
import styled from "styled-components";
import TopAnimation from "../../Components/Animation/TopAnimation";

function Bullets(props) {
  return (
    <TopAnimation duration={1} y={10}>
      <Section hasTitle={props.hasTitle}>
        <ul>
          {props.points.map((point) => {
            return <li key={point.name}>{point.name}</li>;
          })}
        </ul>
      </Section>
    </TopAnimation>
  );
}

const Section = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 51% 50%;
  overflow: hidden;
  padding-left: ${(props) =>
    props.hasTitle
      ? "calc(var(--width-medium) + var(--width-large))"
      : "var(--width-medium)"};
  margin-bottom: 3rem;

  ul {
    padding-left: 5%;
  }

  li {
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: Lato, sans-serif;
    font-weight: 400;
  }

  @media (max-width: 1280px) {
    padding-left: var(--width-medium);
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
  }

  @media (max-width: 525px) {
    grid-template-columns: 100%;
    padding-right: 10%;
  }
`;

export default Bullets;
