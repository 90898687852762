import React, { useRef, useCallback, useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import styled from "styled-components";
import Lines from "../../Components/Background/Lines";
import HorizontalNavbar from "../../Components/Navigation/HorizontalNavbar";
import VerticalNavbar from "../../Components/Navigation/VerticalNavbar";
import NewWork from "../../Components/WorksPage/NewWork";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../../Components/Navigation/Footer";

import workData from "../../Data/works.json";

function NewWorksPage() {
  const sliderRef = useRef(null);
  const topRef = useRef(null);
  const [topOffset, setTopOffset] = useState(0);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  useEffect(() => {
    if (inView) {
      setTopOffset(0);
    } else {
      setTopOffset(-100);
    }
  }, [inView]);

  return (
    <>
      <Lines />
      <VerticalNavbar animate={false} topOffset={topOffset} />
      <Content ref={topRef}>
        <HorizontalNavbar
          isLight={false}
          animate={false}
          topOffset={topOffset}
        />
        <NavBox>
          <h1 className="swiper-button-prev" onClick={handlePrev}>
            &larr;
          </h1>
          <h1 className="swiper-button-next" onClick={handleNext}>
            &rarr;
          </h1>
        </NavBox>
        <PseudoDiv ref={ref} />
        <Swiper
          ref={sliderRef}
          navigation={true}
          modules={[Navigation]}
          loop={true}
        >
          {workData.works.map((work, index) => {
            return (
              <SwiperSlide key={index}>
                <NewWork
                  title={work.name}
                  image={work.mainImage}
                  description={work.description}
                  type={work.type}
                  technologies={work.technologies}
                  link={work.link}
                  date={work.date}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Footer ref={topRef} />
      </Content>
    </>
  );
}

const Content = styled.div`
  width: calc(100% - var(--vnavbar-size));
  position: absolute;
  right: 0;
  background-color: var(--color-theme-black);

  @media (max-width: 1024px) {
    width: 100vw;
  }

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-button-next {
    all: unset;
    font-size: 8rem;
    color: white;
    &::after {
      content: "";
    }

    @media (max-width: 765px) {
      font-size: 5rem;
    }
  }
  .swiper-button-prev {
    all: unset;
    font-size: 8rem;
    color: white;
    &::after {
      content: "";
    }
    @media (max-width: 765px) {
      font-size: 5rem;
    }
  }
`;

const NavBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25%;
  left: calc(var(--width-medium) + 4 * var(--width-large));
  height: 5rem;
  width: 16rem;
  z-index: 101;
  cursor: pointer;
  user-select: none;

  @media (max-width: 1024px) {
    top: 10rem;
    left: 5%;
    z-index: 99;
    position: relative;
  }

  @media (max-width: 595px) {
    width: 12rem;
  }
`;

const PseudoDiv = styled.div`
  position: absolute;
  height: 10rem;
  top: 0;
  width: 10rem;
`;

export default NewWorksPage;
