import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

function TopAnimation({ duration = 1, delay = 0, children, y = 20 }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const variants = {
    initial: { opacity: 0, y: `${y}%` },
    final: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("final");
    } else {
      // controls.start("initial");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="initial"
      variants={variants}
      animate={controls}
      transition={{ duration: duration, delay: delay }}
      style={{
        position: "relative",
        width: "100%",
        zIndex: 100,
        height: "100%",
      }}
    >
      {children}
    </motion.div>
  );
}

export default TopAnimation;
