import React, { useEffect } from "react";
import styled from "styled-components/macro";

import { motion, useAnimationControls } from "framer-motion";
import { HashLink } from "react-router-hash-link";

import { ReactComponent as Instagram } from "../../svg/instagram.svg";
import { ReactComponent as Facebook } from "../../svg/facebook.svg";
import { ReactComponent as LinkedIn } from "../../svg/linkedin.svg";
import { ReactComponent as Behance } from "../../svg/behance.svg";
import { ReactComponent as Pinterest } from "../../svg/pinterest.svg";
import { ReactComponent as Dribbble } from "../../svg/dribbble.svg";
import { ReactComponent as Whatsapp } from "../../svg/whatsapp.svg";

function VerticalNavbar({ navlinks, activeDiv, topOffset, animate = true }) {
  const controls = useAnimationControls();

  useEffect(() => {
    if (topOffset < -40) {
      controls.start({ y: -40, scale: 0.9 });
    } else {
      controls.start({ y: 0, scale: 1 });
    }
  }, [topOffset, controls]);

  const motionVariants = {
    initial: { translateX: "-12rem" },
    final: { translateX: 0 },
  };

  const logoVariants = {
    initial: { opacity: 0 },
    final: { opacity: 1 },
  };

  const linkVariants = {
    initial: { scaleY: 0 },
    final: { scaleY: 1 },
  };
  return (
    <NavbarBody>
      <motion.div
        initial={animate ? "initial" : "final"}
        animate="final"
        transition={{ duration: 0.5 }}
        className="body"
        variants={motionVariants}
      >
        <a href="/">
          <motion.div
            initial={animate ? "initial" : "final"}
            animate="final"
            transition={{ duration: 1, delay: 0.5 }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            variants={logoVariants}
          >
            <Logo
              as={motion.img}
              src="/assets/logo_black.png"
              alt="Logo"
              animate={controls}
            />
          </motion.div>
        </a>

        <NavSections
          as={motion.div}
          initial={animate ? "initial" : "final"}
          animate="final"
          transition={{ duration: 0.5, delay: 3 }}
          variants={linkVariants}
        >
          {navlinks &&
            navlinks.map((link, index) => {
              return (
                <NavbarLink
                  smooth
                  key={index}
                  to={`/#${link}`}
                  className={index === activeDiv && "selected"}
                >
                  &nbsp;
                </NavbarLink>
              );
            })}
        </NavSections>
        <SocialLinks
          as={motion.div}
          initial={animate ? "initial" : "final"}
          animate="final"
          transition={{ duration: 0.5, delay: navlinks ? 3.5 : 2 }}
          variants={linkVariants}
          navlinks={navlinks}
        >
          <NavbarLink
            as="a"
            href="https://dribbble.com/theicondesigns"
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <Dribbble />
          </NavbarLink>
          <NavbarLink
            as="a"
            href="https://in.pinterest.com/theicondesigns/"
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <Pinterest />
          </NavbarLink>
          <NavbarLink
            as="a"
            href="https://www.behance.net/theicondesigns"
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <Behance />
          </NavbarLink>
          <NavbarLink
            as="a"
            href="https://www.linkedin.com/company/icon-venture-company"
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <LinkedIn />
          </NavbarLink>
          <NavbarLink
            as="a"
            href="https://www.facebook.com/icon.venture.company"
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <Facebook />
          </NavbarLink>
          <NavbarLink
            as="a"
            href="https://www.instagram.com/theicondesigns/"
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <Instagram />
          </NavbarLink>
          <NavbarLink
            as="a"
            href="https://wa.me/+919106555101"
            target="_blank"
            rel="noreferrer"
            className="social"
          >
            <Whatsapp />
          </NavbarLink>
        </SocialLinks>
      </motion.div>
    </NavbarBody>
  );
}

const NavbarBody = styled.div`
  position: fixed;
  height: 100vh;
  width: var(--vnavbar-size);
  font-family: "Lato", sans-serif;
  font-weight: 500;
  transition: all 0.5s;
  background-color: var(--color-theme-black);

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    background-color: var(--color-secondary-dark);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 70%;
  display: block;
`;

const NavSections = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform-origin: top;

  .selected {
    opacity: 1;
  }

  .not {
    color: red;
  }
`;

const NavbarLink = styled(HashLink)`
  text-decoration: none;
  height: 3.6rem;
  margin: 0.5rem 0;
  width: 0.5rem;
  background-color: var(--color-theme-black);
  opacity: 0.2;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: opacity 0.2s linear;

  &.social {
    width: fit-content;
    background-color: transparent;
    font-size: 2rem;
    writing-mode: vertical-lr;
    margin: 0.2rem 0;
    color: var(--color-theme-black);

    &:hover {
      svg {
        opacity: 1;
      }
    }

    all: unset;
    font-size: 1.5rem;
    fill: var(--color-primary-dark);
    cursor: pointer;

    svg {
      opacity: 0.5;
      width: 2rem;
      height: 2rem;
      margin-bottom: 1rem;
      fill: var(--color-primary-dark);
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transform-origin: top;
  margin-top: ${(props) => (!props.navlinks ? "15rem" : "")};
`;

export default VerticalNavbar;
