import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

function Hero() {
  const { width } = useWindowDimensions();
  const baseDelay = width > 1024 ? 1.3 : 0;

  return (
    <HeroDiv>
      <HeroVideo id="heroVideo">
        <img src="/assets/Hero_background.png" alt="hero" />
      </HeroVideo>
      <StartButton to="/#design" smooth>
        <motion.div
          initial={{ rotate: "-90deg", opacity: 0, translateX: "20%" }}
          animate={{ translateX: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: baseDelay + 1.5 }}
          className="link"
        >
          <motion.button
            className="arrow"
            initial={{ x: 0 }}
            animate={{ x: "-30%" }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.5,
              delay: baseDelay + 2.5,
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </motion.button>
          <span className="text">start</span>
        </motion.div>
      </StartButton>
      <HeroText>
        <HeroSubtitle>
          <motion.div
            initial={{ x: "-50%", opacity: 0 }}
            animate={{ x: "0%", opacity: 1 }}
            transition={{ duration: 0.2, delay: baseDelay + 0.5 }}
          >
            &nbsp;
          </motion.div>
          <motion.p
            initial={{ x: "-50%", opacity: 0 }}
            animate={{ x: "0%", opacity: 1 }}
            transition={{ duration: 0.3, delay: baseDelay + 0.5 }}
          >
            engaging people creatively
          </motion.p>
        </HeroSubtitle>
        <HeroMainText>
          <motion.p
            initial={{ translateX: "-10%", opacity: 0 }}
            animate={{ translateX: 0, opacity: 1 }}
            transition={{ duration: 0.4, delay: baseDelay + 0.8 }}
            className="icon"
          >
            icon
          </motion.p>
          <br />
          <motion.p
            initial={{ translateX: "-10%", opacity: 0 }}
            animate={{ translateX: 0, opacity: 1 }}
            transition={{ duration: 0.4, delay: baseDelay + 1 }}
            className="vc"
          >
            venture company
          </motion.p>
        </HeroMainText>
      </HeroText>
    </HeroDiv>
  );
}

const HeroDiv = styled.section`
  width: 100%;
  height: 101%;
  position: relative;
  overflow: hidden;
  background-color: var(--color-theme-black);
`;

const HeroVideo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    object-position: 70% 0%;
    width: 100%;
    height: 101%;
  }

  @media (max-width: 525px) {
    img {
      object-position: 75%;
    }
  }
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6rem;
  left: calc(var(--width-medium) + var(--width-small));
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;

  @media (max-width: 1024px) {
    left: 8rem;
    bottom: 30%;
  }

  @media (max-width: 525px) {
    left: 4rem;
  }
`;

const StartButton = styled(HashLink)`
  .link {
    transform-origin: 0%;
    cursor: pointer;
    left: calc(var(--width-small) - 2rem);
    bottom: 0;
    position: absolute;
    text-decoration: none;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px var(--color-secondary-dark);

    .text {
      font-size: 10rem;
    }

    .arrow {
      all: unset;
      font-size: 10rem;
      color: var(--color-secondary-dark);
      margin-right: 2rem;
    }

    @media (max-width: 1024px) {
      left: var(--width-small);

      .text {
        display: none;
      }

      .arrow {
        font-size: 15rem;
      }
    }

    @media (max-width: 525px) {
      bottom: 10%;

      .arrow {
        font-size: 10rem;
      }
    }

    &:hover {
      -webkit-text-fill-color: var(--color-secondary-dark);
    }
  }
`;

const HeroSubtitle = styled.div`
  z-index: 99;
  display: flex;
  align-items: center;
  margin-bottom: 5rem;

  p {
    color: white;
    font-size: 2.5rem;
  }

  div {
    width: 15rem;
    background-color: var(--color-secondary-dark);
    height: 1.5px;
    margin-right: 1.5rem;
  }

  @media (max-width: 1024px) {
    left: var(--width-small);
    top: 32rem;

    div {
      width: 10rem;
    }
  }

  @media (max-width: 765px) {
    left: var(--width-small);
    top: 32rem;
    margin-bottom: 0;

    p {
      font-size: 1.8rem;
    }

    div {
      width: 5rem;
    }
  }

  @media (max-width: 525px) {
    left: var(--width-small);
    top: 40rem;

    p {
      font-size: 1.5rem;
    }

    div {
      width: 3rem;
      margin-right: 1rem;
    }
  }
`;

const HeroMainText = styled.div`
  z-index: 99;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  p,
  span {
    line-height: 15rem;
  }

  .icon {
    font-size: 17vw;
    text-transform: uppercase;
    margin-left: -2%;
    font-family: Montserrat, "sans-serif";
    font-weight: 800;
  }

  .vc {
    font-size: 4.5vw;
    font-family: Lato, "sans-serif";
    font-weight: 600;
    text-transform: uppercase;
  }

  @media (max-width: 1024px) {
    left: var(--width-small);
    top: 35rem;

    .vc {
      line-height: 5rem;
    }

    p,
    span {
      font-size: 14rem;
    }
  }

  @media (max-width: 765px) {
    left: var(--width-small);
    top: 35rem;

    p,
    span {
      font-size: 10rem;
    }
  }

  @media (max-width: 525px) {
    left: var(--width-small);
    top: 42rem;

    .icon {
      font-size: 23vw;
    }

    .vc {
      font-size: 6vw;
    }

    p,
    span {
      font-size: 7rem;
      line-height: 6rem;
    }
  }

  @media (min-width: 1900px) {
    p,
    span {
      line-height: 23rem;
    }
  }
`;

export default Hero;
