import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

function ServiceCardCar(props) {
  return (
    <Card>
      <Link to={props.link}>
        <img src={props.icon} alt={props.title} />
        <h3>{props.title}</h3>
        <p>{props.about}</p>
        more <span>&rarr;</span>
        <p className="index">{props.index}</p>
      </Link>
    </Card>
  );
}

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 4rem;
    margin-bottom: 3%;
    color: var(--color-primary-dark);
  }

  p {
    /* color: #bbb; */
    color: var(--color-primary-dark);
    font-size: 1.5rem;
    margin-bottom: 3%;
  }

  a {
    all: unset;
    color: var(--color-secondary-dark);
    font-size: 1.4rem;

    span {
      font-size: 2rem;
      margin-left: 1rem;
    }
  }

  .index {
    all: unset;
    position: absolute;
    bottom: -5%;
    right: 5%;
    font-size: 7rem;
    font-weight: 800;
    font-family: Lato, "sans-serif";
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px black;

    ::before {
      content: "0";
    }
  }
`;

export default ServiceCardCar;
