import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Redirect(props) {
  return (
    <Div to="services">
      <FontAwesomeIcon icon={faArrowRight} className="arrow" />
      <p>all</p>
    </Div>
  );
}

const Div = styled(Link)`
  all: unset;
  cursor: pointer;
  height: 100%;
  width: 16%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: var(--color-secondary-dark);
  user-select: none;
  transition: all 0.5s;

  &:hover {
    background-color: var(--color-secondary-dark);

    .arrow {
      color: white;
    }
  }

  p {
    transition: all 0.5s;
    translate: 0 3.5rem;
    font-size: 12rem;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px white;
  }

  .arrow {
    transition: all 0.3s;
    margin: auto;
    font-size: 20rem;
  }
`;

export default Redirect;
