import React, { useState } from "react";
import styled from "styled-components";

import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as Instagram } from "../../svg/instagram.svg";
import { ReactComponent as Facebook } from "../../svg/facebook.svg";
import { ReactComponent as LinkedIn } from "../../svg/linkedin.svg";
import { ReactComponent as Behance } from "../../svg/behance.svg";
import { ReactComponent as Pinterest } from "../../svg/pinterest.svg";
import { ReactComponent as Dribbble } from "../../svg/dribbble.svg";
import { ReactComponent as Whatsapp } from "../../svg/whatsapp.svg";

function MobileHorizontalNavbar() {
  const [showFull, setShowFull] = useState(false);
  const location = useLocation().pathname;

  return (
    <NavbarBody>
      <Bar>
        <Logo>
          <Link to="/">
            <img
              src="/assets/logo_white.png"
              alt="Logo"
              className={showFull ? "full" : "bar"}
            />
          </Link>
        </Logo>
        <Options
          onClick={() => {
            setShowFull(!showFull);
          }}
        >
          <TwoLines>
            <div className={`top ${showFull ? "cross" : ""}`} />
            <div className={`bottom ${showFull ? "cross" : ""}`} />
          </TwoLines>
        </Options>
      </Bar>
      <AnimatePresence>
        {showFull && (
          <Full
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Nav to="/" className={location === "/" ? "active" : ""}>
              Home
            </Nav>
            <Nav
              to="/services"
              className={location === "/services" ? "active" : ""}
            >
              Services
            </Nav>
            <Nav
              to="/clients"
              className={location === "/clients" ? "active" : ""}
            >
              Clients
            </Nav>
            <Nav to="/story" className={location === "/story" ? "active" : ""}>
              Brand Story
            </Nav>
            <Nav
              to="/connect"
              className={location === "/connect" ? "active" : ""}
            >
              Connect Now
            </Nav>
            <Nav
              to="/quote"
              className={location === "/quote" ? "active quote" : "quote"}
            >
              Get a Quote
            </Nav>
            <SocialLinks>
              <a
                href="https://www.instagram.com/theicondesigns/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
              <a
                href="https://www.facebook.com/icon.venture.company"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                href="https://www.linkedin.com/company/icon-venture-company"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedIn />
              </a>
              <a
                href="https://www.behance.net/theicondesigns"
                target="_blank"
                rel="noreferrer"
              >
                <Behance />
              </a>
              <a
                href="https://in.pinterest.com/theicondesigns/"
                target="_blank"
                rel="noreferrer"
              >
                <Pinterest />
              </a>
              <a
                href="https://dribbble.com/theicondesigns"
                target="_blank"
                rel="noreferrer"
              >
                <Dribbble />
              </a>
              <a
                href="https://wa.me/+919106555101"
                target="_blank"
                rel="noreferrer"
                className="social"
              >
                <Whatsapp />
              </a>
            </SocialLinks>
            <Secondary>
              <Link to="/terms">Terms of use</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </Secondary>
          </Full>
        )}
      </AnimatePresence>
    </NavbarBody>
  );
}

const NavbarBody = styled.div`
  z-index: 105;
`;

const SocialLinks = styled.div`
  display: flex;
  width: 85%;
  margin-top: 3rem;
  padding-left: 0.5rem;
  align-items: center;
  max-height: 3rem;

  a {
    all: unset;
    font-size: 1.3rem;
    color: #777;
    color: var(--color-tertiary-dark);
    margin-right: 2rem;
    fill: #777;
    fill: var(--color-tertiary-dark);

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const Full = styled.div`
  width: 100vw;
  height: calc(100vh - var(--hnavbar-size));
  background-color: var(--color-theme-black);
  position: fixed;
  top: var(--hnavbar-size);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 0 3rem;
  font-size: 9rem;
  font-family: Lato, sans-serif;
  font-weight: 400;
  color: white;

  .active {
    color: #54bab9;
  }

  .quote {
    color: var(--color-secondary-dark);
  }

  @media (max-width: 525px) {
    font-size: 7rem;
  }
`;

const Secondary = styled.div`
  a {
    all: unset;
    display: block;
    margin-bottom: 2rem;
  }

  margin-top: 3rem;
  width: 100%;
  color: #777;
  color: var(--color-tertiary-dark);

  font-size: 1.5rem;
`;

const Nav = styled(Link)`
  text-decoration: none;
  color: var(--color-theme-white);
`;

const Bar = styled.div`
  width: 100vw;
  height: var(--hnavbar-size);
  background-color: var(--color-theme-black);
  position: fixed;
  top: 0;
  transition: all 0.1s;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  padding-left: 3rem;

  img {
    transition: all 0.3s;
    width: 4.5rem;
  }

  .bar {
    opacity: 1;
  }

  .full {
    opacity: 0;
  }
`;

const Options = styled.div`
  background-color: white;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 2rem;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 102;
`;

const TwoLines = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    margin: 0 auto;
    width: 50%;
    height: 2px;
    background-color: var(--color-secondary-dark);
    transform-origin: 0%;
    transition: all 0.3s;

    &.top {
      margin-bottom: 0.6rem;

      &.cross {
        rotate: 45deg;
        translate: 3px -2px;
      }
    }

    &.bottom {
      &.cross {
        translate: 3px 2px;
        rotate: -45deg;
      }
    }
  }
`;

export default MobileHorizontalNavbar;
