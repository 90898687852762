import React, { useEffect } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import SideAnimation from "../Animation/SideAnimation";

const TopDiv = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.8,
  });

  useEffect(() => {
    props.getOffsetBool(inView);
  }, [inView, props]);

  return (
    <Div ref={ref}>
      <SideAnimation duration={1} x={-40}>
        {props.type === "main" ? (
          <MainHeading>{props.text}</MainHeading>
        ) : (
          <SubHeading>{props.text}</SubHeading>
        )}
      </SideAnimation>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  background-color: var(--color-theme-black);
  height: 37vh;
  position: relative;
  top: 0;
  right: 0;
  padding-right: 2rem;
`;

const MainHeading = styled.h1`
  position: absolute;
  left: var(--width-medium);
  bottom: -7.5%;
  color: white;

  z-index: 100;
  color: var(--color-theme-white);
  font-size: 10rem;

  @media (max-width: 1024px) {
    bottom: 10%;
    left: 5%;
    font-size: 6rem;
  }

  @media (max-width: 525px) {
    font-size: 4rem;
  }
`;

const SubHeading = styled(MainHeading)`
  bottom: 0;
  left: calc(var(--width-medium) + 1rem);
  color: white;
  font-size: 9rem;

  @media (max-width: 1200px) {
    font-size: 7.4rem;
  }

  @media (max-width: 1024px) {
    bottom: 10%;
    left: 5%;
    font-size: 6rem;
  }

  @media (max-width: 525px) {
    font-size: 4rem;
  }
`;

export default TopDiv;
