import React from "react";
import styled from "styled-components";
import TopAnimation from "../../Components/Animation/TopAnimation";
import BenefitCard from "../../Components/ServicePage/BenefitCard";

function Points(props) {
  return (
    <TopAnimation duration={1} y={10}>
      <Section hasTitle={props.hasTitle}>
        {props.points.map((point, index) => {
          return (
            <BenefitCard
              key={index}
              letter={point.name.charAt(0)}
              title={point.name}
              description={point.about}
            />
          );
        })}
      </Section>
    </TopAnimation>
  );
}

const Section = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 26% 25% 25% 25%;
  overflow-x: hidden;
  padding-left: ${(props) =>
    props.hasTitle
      ? "calc(var(--width-medium) + var(--width-large))"
      : "var(--width-medium)"};
  margin-bottom: 2rem;

  @media (max-width: 1280px) {
    padding-left: var(--width-medium);
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
  }

  @media (max-width: 525px) {
    grid-template-columns: 100%;
  }
`;

export default Points;
