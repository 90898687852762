import React, { useState } from "react";
import styled from "styled-components";

const Youtube = (props) => {
  const { videoId, width, height, imgSize } = props;
  const [showVideo, setShowVideo] = useState(false);
  return (
    <>
      {showVideo ? (
        <iframe
          width={width || "100%"}
          height={height || "100%"}
          title="YouTube video player"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&showinfo=0`}
          allow="autoplay"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : (
        <Div
          className="image"
          style={{
            backgroundImage: `url(${`https://i.ytimg.com/vi_webp/${videoId}/${
              imgSize || "maxresdefault"
            }.webp`})`,
            width,
            height,
          }}
          onClick={() => setShowVideo(true)}
        >
          <div className="playButton" />
        </Div>
      )}
    </>
  );
};

const Div = styled.div`
  position: relative;
  display: flex;
  /* display: inline-flex; */
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  cursor: pointer;
  height: 100%;
  width: 100%;

  .playButton {
    cursor: pointer;
    position: relative;
    height: 60px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
  }

  .playButton:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .playButton:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
  }
  .image:hover .playButton {
    background-color: #ff0000;
  }
`;

export default Youtube;
