import React from "react";
import styled from "styled-components";

function BenefitCard(props) {
  return (
    <Card>
      <h1>{props.letter}</h1>
      <h3>{props.title}</h3>
      {props.description && <p>{props.description}</p>}
    </Card>
  );
}

const Card = styled.div`
  width: 100%;
  padding-right: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: baseline;

  h1 {
    color: gray;
    opacity: 0.3;
    font-size: 6rem;
    line-height: 6.5rem;
    font-family: Lato, sans-serif;
    position: relative;
  }

  h3 {
    font-family: Lato, sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-theme-black);
  }

  p {
    opacity: 0.6;
    font-size: 1.5rem;
    color: gray;
    font-family: Lato, sans-serif;
    font-weight: 300;
  }

  @media (max-width: 525px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h1 {
      min-width: 5rem;
    }

    h3 {
      text-align: left;
    }

    p {
      display: none;
    }
  }
`;

export default BenefitCard;
