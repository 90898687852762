import React from "react";
import styled from "styled-components";

function CapabilityCard(props) {
  return (
    <Card>
      <h1>{props.index}</h1>
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </Card>
  );
}

const Card = styled.div`
  width: 100%;
  padding-right: 13rem;
  padding-bottom: 3rem;
  h1 {
    color: var(--color-primary-dark);
    font-size: 8rem;
    line-height: 6.5rem;
    font-family: Lato, sans-serif;
  }

  h3 {
    font-family: Lato, sans-serif;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    color: var(--color-primary-dark);
  }

  p {
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 300;
    font-family: Lato, sans-serif;
  }

  @media (max-width: 525px) {
    padding-right: 5%;
  }
`;

export default CapabilityCard;
