import React from "react";
import styled from "styled-components";
import TopAnimation from "../../Components/Animation/TopAnimation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Presentation(props) {
  return (
    <TopAnimation duration={1}>
      <Section>
        <Swiper
          navigation={true}
          pagination={{
            type: "progressbar",
          }}
          modules={[Navigation, Pagination]}
        >
          {props.presentation.slides.map((slide, index) => {
            return (
              <SwiperSlide key={slide.location + index}>
                <img src={slide.location} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <a href={props.presentation.link} target="_blank" rel="noreferrer">
          Download
        </a>
      </Section>
    </TopAnimation>
  );
}

const Section = styled.div`
  width: 100%;
  overflow: hidden;
  padding-left: calc(var(--width-medium) + var(--width-large));
  padding-right: 5%;
  margin: 5rem 0;

  @media (max-width: 1280px) {
    padding-left: var(--width-medium);
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
  }

  @media (max-width: 525px) {
    grid-template-columns: 100%;
  }

  a {
    display: inline-block;
    text-decoration: none;
    font-size: 1.6rem;
    color: var(--color-theme-black);
    background-color: var(--color-tertiary-dark);
    padding: 1rem;
    border-radius: 0.5rem;
    font-weight: 800;
  }

  .swiper {
    margin-bottom: 2rem;
  }

  .swiper-slide {
    img {
      width: 100%;
      pointer-events: none;
    }
  }
`;

export default Presentation;
