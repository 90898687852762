import { animate, useAnimation, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

function Stat(props) {
  const textRef = useRef();
  const pControls = useAnimation();
  const [ref, inView] = useInView();

  const variants = {
    initial: { opacity: 0, y: "20%" },
    final: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (inView) {
      pControls.start("final");
    } else {
      pControls.start("initial");
    }
  }, [pControls, inView]);

  useEffect(() => {
    const controls = animate(0, props.number, {
      duration: 1,
      onUpdate(value) {
        textRef.current.textContent = value.toFixed(0);
      },
    });

    return () => controls.stop();
  }, [props.number, inView]);

  return (
    <Container>
      <h1 ref={textRef} className={props.plus ? "plus" : ""}>
        {props.number}
      </h1>
      <motion.p
        ref={ref}
        variants={variants}
        animate={pControls}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        {props.text}
      </motion.p>
    </Container>
  );
}

export default Stat;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 101;

  h1 {
    font-size: 8rem;
    font-family: Montserrat, "sans-serif";
    font-weight: 800;

    &.plus {
      ::after {
        content: "+";
      }
    }
  }

  p {
    font-size: 3rem;
    font-family: Lato, "sans-serif";
    font-weight: 600;
    text-align: center;
  }

  @media (max-width: 765px) {
    h1 {
      font-size: 5rem;
    }

    p {
      font-size: 1.5rem;
    }
  }
`;
